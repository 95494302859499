import React, { useEffect, useState } from "react";
import { Button, Flex, Spin, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { formatPhoneNumber } from "../../utils/phoneLib";
import { useGetAutoReplyByDid } from "../../hooks/apis/auto_reply_rules.api";
import { AutoReplyRule } from "../../store/slices/auto_reply_rule";
import AutoReplyRuleForm from "../Form/AutoReplyRule";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useGetTemplates } from "../../hooks/apis/templates.api";
import Loader from "../generic/Loader";

const { Title } = Typography;
const spinStyle: React.CSSProperties = {
  width: "100%",
  height: "calc(100% - 80px)",
};

const AddAutoReplyRules = ({
  did
}: {
  did: string
}) => {
  const { data } = useSelector((state: RootState) => state.autoReplyRules);
  const { data: user } = useSelector((state: RootState) => state.user);
  const [rules, setRules] = useState<AutoReplyRule[]>(data.filter((rule) => rule.did === did));
  const { isLoading: autoReplyLoading } =
    useGetAutoReplyByDid(did as string);
  const { refetch } = useGetTemplates(user?.accountID);
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    setRules(data.filter((rule) => rule.did === did));
  }, [did, data]);
  const removeRuleByIndex = (index: number) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    setRules(newRules);
  };


  return (
    <div style={{ padding: "10px" ,height:"100%" }}>
      <Flex justify="space-between">
        <Title>Auto Reply Rules</Title>{" "}
      </Flex>
      {autoReplyLoading ? <Flex style={spinStyle} justify="center" align="center">
        <Loader />
      </Flex> : rules.map((rule, index) => {
        return (
          <AutoReplyRuleForm
            rule={rule}
            index={index}
            removeRule={removeRuleByIndex}
          />
        );
      })}
      {
        !autoReplyLoading && <Button
          type="dashed"
          onClick={() => {
            const newRules = [...rules];
            newRules.push({
              did: did as string,
              rule: {
                conditions: [],
                label: "",
              },
              enabled: true,
              id: "",
              position: 0,
              template: "",
            });
            setRules(newRules);
          }}
          block
        >
          + Add Rule
        </Button>
      }

    </div>
  );
};

export default AddAutoReplyRules;
