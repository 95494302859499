import { Avatar, Flex,  Popover } from "antd";
import { BaseMessage } from "../../../interfaces/messages.type";
import { generateNameSpaceColor } from "../../../utils/color";
import { formatPhoneNumber } from "../../../utils/phoneLib";
import phoneImg from "../../../assets/images/icon/Phone-call.svg";
import dayjs from "dayjs";
import { TbMessageCircleDown } from "react-icons/tb";
import { TbMessageCircleUp } from "react-icons/tb";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {  SmsStatistics } from "../../../hooks/apis/reports.api";
import { useGetUsersByDids } from "../../../hooks/apis/user.api";

const avatarStyle: React.CSSProperties = {
  verticalAlign: "middle",
  color: "black",
  fontWeight: "bold",
};

const bodyStyle: React.CSSProperties = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: "#8c8c8c",
  width:"160px",
  display:"block"
};

const MessagesTable: React.FC<{  statistics: SmsStatistics ,messages: BaseMessage[] , page: number ,isLoading: boolean }> = ({ statistics , messages , page ,isLoading}) => {
  const pageSize = 20;
  const navigate = useNavigate();
  const { data: users } = useSelector((state: RootState) => state.didUsers);
  const { data: dids } = useSelector((state: RootState) => state.userDids);
  const { refetch: fetchAllUsers } = useGetUsersByDids(dids.map((did) => did.did));

  useEffect(() => {
    if (dids.length) {
      fetchAllUsers();
    }
  }, [dids])

  const columns: ColumnsType<BaseMessage> = [
    {
      title: "SENDER/RECIPIENT",
      dataIndex: "sender_id",
      key: "sender_id",
      onHeaderCell: () => ({ style: { background: "white" } }),
      render: (_, record) => {
        const color = generateNameSpaceColor(record.did);

        return (
          <Flex align="center" gap={10}>
            <Avatar style={{ ...avatarStyle, background: color }} size="small">
              {record.did[record.did.length - 1]}
            </Avatar>
            <span className="text-xs font-weight-bold">{formatPhoneNumber(record.participant)}</span>
          </Flex>
        );
      },
    },
    {
      title: "NUMBER",
      dataIndex: "did",
      key: "did",
      render: (_, record) => {
        return (
          <Flex align="center" gap={10}>
            <img src={phoneImg} alt="" className="avatar-sm" />
            <span  className="text-xs font-weight-bold">{formatPhoneNumber(record.did)}</span>
          </Flex>
        );
      },
    },
    {
      title: "DIRECTION",
      dataIndex: "direction",
      key: "direction",
      render: (_, record) => {
        return (
          <Flex align="center" gap={10}>
            {record.direction === "outbound" ? <TbMessageCircleUp color="#1890ff" size={20} /> : <TbMessageCircleDown color="green" size={20} />}
            <span className="text-xs font-weight-bold">{record.direction.charAt(0).toUpperCase() + record.direction.slice(1)} </span>
          </Flex>
        );
      },
    },
    {
      title: "USER",
      dataIndex: "user_id",
      key: "user_id",
      render: (_, record) => {
        const user = users.find((user) => user.id === record.sender_id);
        return (
          <>
            {" "}
            {record.direction === "outbound" ? (
              <span className="text-xs font-weight-bold">{`${user?.first_name} ${user?.last_name}`.charAt(0).toUpperCase() + `${user?.first_name} ${user?.last_name}`.slice(1)}</span>
            ) : (
              <span className="text-xs font-weight-bold"></span>
            )}
          </>
        );
      },
    },
    {
      title: "BODY",
      dataIndex: "body",
      key: "body",
      render: (_, record) => {
        return (
          <Popover content={record.body}>
            <span style={bodyStyle} className="text-xs font-weight-bold">{record.body}</span>
          </Popover>
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const color = generateNameSpaceColor(record.did);
        let statusClass = "";
        if ((record.status === "delivered" || record.status === "sent")  && record.direction === "outbound") {
          statusClass = "text-green";
        } else if (record.status === "failed" && record.direction === "outbound") {
          statusClass = "text-red";
        } else if (record.status === "pending" && record.direction === "outbound") {
          statusClass = "text-yellow";
        } else if (record.direction === "inbound") {
          statusClass = "text-blue";
        }
        return (
          <span className={`text-xs ${statusClass} font-weight-bold`}>
            {record.status === "delivered" ? "Sent" : record.direction === "inbound" ? "Received" : record.status && record.status.charAt(0).toUpperCase() + record.status?.slice(1)}
          </span>
        );
      },
    },
    {
      title: "DATE AND TIME",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => {
        return <span className="text-xs font-weight-bold">{dayjs(record.timestamp).format("MM/DD/YYYY hh:mm A")}</span>;
      },
    },
  ];

  return (
    <div>
      <Table
        className="messages-report-table"
        columns={columns}
        dataSource={messages}
        loading={isLoading}
        scroll={{ y: 400 }}
        pagination={{
          onChange: (page) => {
            navigate(`/reports/list/${page}`);
          },
          current: Number(page),
          pageSize: pageSize,
          total: statistics.total,
          position: ["none", "bottomCenter"],
        }}
      />
    </div>
  );

};

export default MessagesTable;
