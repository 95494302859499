import { Flex, Input, List, Tabs, TabsProps } from "antd"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { SearchedConversation, useSearchConversations } from "../../../hooks/apis/conversations.api";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { debounce } from "../../../utils/debounce";
import { SearchConversationCard } from "./SearchConversations.card";
import UseScrollObserver from "../../../hooks/scroll-observer";
import Loader from "../../generic/Loader";

const ItemContentStyle: React.CSSProperties = {
    width: "100%",
    padding: "5px",
    borderRadius: "10px",
};

const spinStyle: React.CSSProperties = {
    width: "100%",
    height: "calc(100% - 80px)",
};


export const SearchConversationList = ({
    setIsmodal
}: {
    setIsmodal: Function
}) => {
    const [searchInput, setSearchInput] = useState<string>("")
    const [searchType, setSearchType] = useState<"conversation" | "contact">("conversation")
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [visibleMessage, setVisibleMessage] = useState<number | null>(null);
    const [conversations, setConversations] = useState<SearchedConversation[]>([])

    const cardsRefs = useRef<(HTMLDivElement | null)[]>([]);

    const { selectedDid } = useSelector(
        (state: RootState) => state.userDids
    );
    const { data: contacts } = useSelector((state: RootState) => state.contacts);

    const { mutate: searchConversationHandler, data, isLoading: searchConversationLoading } = useSearchConversations()

    UseScrollObserver({
        cardRefs: cardsRefs,
        setVisibleMessage,
        cards: conversations
    });

    const handleFetch = (before: string) => {
        let participants: string[] = []
        if (searchType === "contact") {
            participants = contacts.filter((contact) => {
                return contact.phones.some((phone) => {
                    return phone.phone_number.includes(searchInput)
                }) || `${contact.first_name ? contact.first_name : ""}${contact.middle_name ? " " + contact.middle_name : ""}${contact.last_name ? " " + contact.last_name : ""}`.toLowerCase().includes(searchInput)
            }).flatMap((contact) => contact.phones.map((phone) => phone.phone_number))
        }

        searchConversationHandler({
            did: selectedDid as string,
            search: searchInput,
            participants,
            searchType,
            before
        }, {
            onSuccess: (data) => {
                setConversations((prev)=>[...prev, ...data])
                if (data.length < 20) {
                    setHasMore(false)
                }
            }
        })
    }

    useEffect(() => {
        if (conversations.length && visibleMessage && visibleMessage > conversations.length - 3 && hasMore && !searchConversationLoading) {
            handleFetch(conversations[conversations.length - 1]?.timestamp)
        }
    }, [visibleMessage, conversations])


    useEffect(() => {
        setConversations([])
        setHasMore(true)
        if (searchInput.length > 0 && /\S/.test(searchInput)) {
            handleFetch(new Date().toISOString())
        }
    }, [searchInput, searchType])


    const handleSearchChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
        setConversations([])
        setSearchInput(event.target.value.toLowerCase());
    }, 300);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Conversations',
            active: true,
        },
        {
            key: '2',
            label: 'Contacts',
        }
    ];



    return <div
        style={{
            padding: "10px",
        }}
    >
        <Input
            style={{
                border: "1px solid rgb(223, 226, 232)",
                borderRadius: "25px",
                background: "#F5F6F7",
                height: "40px",

            }}
            size="middle"
            onChange={
                handleSearchChange
            }
            placeholder="Search"
        />
        <Tabs defaultActiveKey="1" onChange={(key) => {
            setSearchType(key === "1" ? "conversation" : "contact")
        }} items={items} />
        <Flex
            align="flex-start"
            justify="space-evenly"
            style={{
                ...ItemContentStyle,
            }}
            wrap="wrap"
            gap="small"
        >

            <div
                style={{
                    height: "400px",
                    overflow: "auto",
                    width: "100%"
                }}
            >
                {conversations?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()).map((conversation, index) => {
                    return <div
                        data-index={index}
                        key={index}
                        ref={(element) => {
                            cardsRefs.current[index] = element;
                        }}
                    ><SearchConversationCard searchType={searchType} setIsModal={setIsmodal} searchInput={searchInput} conversation={conversation} />
                    </div>
                })}
                {
                    searchConversationLoading && <Flex style={spinStyle} justify="center" align="center">
                        <Loader />
                    </Flex>
                }
            </div>

        </Flex>
    </div>
}