import { api } from "@data-phone/react-resource-server-auth";
import qs from "qs";
import { useQuery } from "react-query";
import { BaseMessage } from "../../interfaces/messages.type";

export interface SmsStatistics {
  total: number;
  failed: number;
  pending: number;
  unread: number;
  read: number;
  delivered: number;
  received: number;
  scheduled: number;
}

export interface SmsReportQuery {
  offset: number;
  limit?: number;
  start_date: Date;
  end_date: Date;
  start_time: Date | null;
  end_time: Date | null;
  direction: string | null;
  user: string | null;
  account_id: string;
  dids: string[] | null;
  searchInput: string | null;
}

const getSmsReportApi = async (query: SmsReportQuery) => {
  const response = await api.get(`${process.env.REACT_APP_SMS_API_URL}/sms/reports?${qs.stringify({...query})}`);
  return response.data;
};

export const useGetSmsReport = (query: SmsReportQuery) => {
  return useQuery<{ messages: BaseMessage[]; statistics: SmsStatistics }, Error>({
    queryKey: ["smsReport", query],
    queryFn: () => getSmsReportApi(query as any),
    enabled: false,
  });
};
