import { useEffect, useRef, useState } from "react";

type CallbackState<T> = [T, (value: T | ((prev: T) => T), callback?: () => void) => void];

function useCallbackState<T>(initialValue: T): CallbackState<T> {
    const [state, _setState] = useState<T>(initialValue);
    const callbackQueue = useRef<(() => void)[]>([]);

    useEffect(() => {
        callbackQueue.current.forEach((cb) => cb());
        callbackQueue.current.length = 0;
    }, [state]);

    const setState = (
        newValue: T | ((prev: T) => T),
        callback?: () => void
    ): void => {
        _setState(prevState => {
            const nextState = typeof newValue === 'function'
                ? (newValue as (prev: T) => T)(prevState)
                : newValue;
            
            if (typeof callback === 'function') {
                callbackQueue.current.push(callback);
            }

            return nextState;
        });
    };

    return [state, setState];
}

export default useCallbackState;