import { Avatar, Flex, Input, List, Typography } from "antd";
import { HeadingText } from "../generic/Typography";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { generateNameSpaceColor } from "../../utils/color";
import { phoneNumberValidate } from "../../utils/phoneLib";
import { useState } from "react";
import { Contact } from "@data-phone/react-generic/dist/components/Forms/Contacts";
const { Text } = Typography;
const ItemContentStyle: React.CSSProperties = {
  width: "100%",
  padding: "5px",
  borderRadius: "10px",
};
const avatarStyle: React.CSSProperties = {
  color: "black",
  marginRight: "15px",
};
const conversationMessageStyle: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: "rgb(119, 117, 135)",
};

const AllContactTextStyle: React.CSSProperties = {
  fontSize:"18px",
  lineHeight:"30px",
  color:"gray",
  padding:"5px"
}

export const ContactList = ({
  handleSubmit,
  onError,
  searchInput: numberInput,
  isModal,
  handleUpdateContact
}: {
  handleSubmit: (value: string) => void;
  onError: (message: string) => void;
  searchInput: string;
  isModal?: boolean
  handleUpdateContact?:(value:Contact) => void
}) => {
  const { data } = useSelector((state: RootState) => state.contacts);
  const [searchInput, setSearchInput] = useState<string>("");

  const handleClick = (value: string) => {
    if (!phoneNumberValidate(value)) {
      onError("Invalid phone number");
      return;
    }
    handleSubmit(value);
  };

  return (
    <>
      {data.length > 0 ? (
        <div
          style={{
            background: "#F5F6F7",
            padding: "10px",
            height: "max-content",
            border: "1px solid #DFE2E8",
            borderRadius: "10px",
            position: isModal ? "initial" : "absolute",
            transform: isModal ? "none" : "translate(-50%, -50%)",
            left: "50%",
            top: "28%",
          }}
        >
          <Input
            style={{
              border: "1px solid #777587",
              borderRadius: "25px",
              background: "#F5F6F7",
            }}
            size="middle"
            onChange={
              (e) => setSearchInput(e.target.value)
            }
            value={searchInput}
            placeholder="Search "
            prefix={"To:"}
          />
          <Text style={AllContactTextStyle} >All Contacts</Text>
          <Flex
            align="flex-start"
            justify="space-evenly"
            style={{
              ...ItemContentStyle,
            }}
            wrap="wrap"
            gap="small"
          >
            <List
              itemLayout="horizontal"
              style={{
                height: "200px",
                overflow: "auto",
                width:"100%"
              }}
              dataSource={data}
            >
              {data
                .filter((contact) => contact.phones.length > 0)
                .sort((a, b) => {
                  const searchInputValue = numberInput;
                  const aPhone = a.phones[0].phone_number;
                  const bPhone = b.phones[0].phone_number;
                  const name = `${a.first_name || ""} ${a.middle_name || ""} ${a.last_name || ""}`;
                  if (aPhone.includes(searchInputValue) || name.includes(searchInputValue) || name.includes(numberInput)) {
                    return -1;
                  } else if (bPhone.includes(searchInputValue) || name.includes(searchInputValue) || name.includes(numberInput)) {
                    return 1;
                  } else {
                    return aPhone.localeCompare(bPhone);
                  }
                })
                .filter(
                  (cont) =>  `${cont.first_name || ""} ${cont.middle_name || ""} ${cont.last_name || ""}`.toLowerCase().includes(numberInput.toLowerCase())  
                )
                .map((contact, index) => {
                  const color = generateNameSpaceColor(
                    contact.first_name
                  );
                  return contact.phones.map((phone) => {
                    return (
                      <Flex
                        onClick={() => {
                          handleClick(phone.phone_number);
                          if (handleUpdateContact) {
                            handleUpdateContact(contact)
                          }
                        }}
                        key={index}
                        style={{ width: "100%", cursor: "pointer" }}
                      >
                        <Avatar
                          style={{ ...avatarStyle, background: color }}
                          size="large"
                        >
                          {contact.first_name[0]}
                        </Avatar>
                        <Flex vertical={true}>
                          <Flex>
                            <HeadingText
                              text={
                               `${contact.first_name || ""} ${contact.middle_name || ""} ${contact.last_name || ""}`  
                              }
                            />
                          </Flex>
                          <Text style={conversationMessageStyle}>
                            {phone.phone_number}
                          </Text>
                        </Flex>
                      </Flex>
                    );
                  })

                })}{" "}
            </List>
          </Flex>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
