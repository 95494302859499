export const ellipsisString = (text: string, keyword: string, contextLength: number = 10): string => {
  const keywordIndex = text.toLowerCase().indexOf(keyword.toLowerCase());

  if (keywordIndex === -1) {
    return "Keyword not found";
  }

  const start = Math.max(0, keywordIndex - contextLength);
  const end = Math.min(text.length, keywordIndex + keyword.length + contextLength);

  const prefix = start > 0 ? "..." : "";
  const suffix = end < text.length ? "..." : "";

  return `${prefix}${text.substring(start, end)}${suffix}`;
};
