import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../generic/modal";
import { ContactList } from "./contacts.list";
import { AppDispatch, RootState } from "../../store/store";
import {  updateContact } from "../../store/slices/contact.slice";
import { useUpdateContact } from "../../hooks/apis/contacts.api";
import { useMessageToast } from "../Layout/DefaultLayout";
import { AddContactForm } from "@data-phone/react-generic";
import { Contact } from "@data-phone/react-generic/dist/components/Forms/Contacts";
import { useGetTagsByCategory } from "../../hooks/apis/tags.api";

const ExistingContactModal = ({ setIsModalOpen, isModalOpen,editData }: { setIsModalOpen: any; isModalOpen: boolean ,editData: Contact}) => {
  const { selectedConversation } = useSelector((state: RootState) => state.conversation);
  const { data: tags } = useSelector((state: RootState) => state.tags);
  const { data: categories } = useSelector((state: RootState) => state.categories);

  const { messageApi } = useMessageToast();
  const { mutate: updateContactMutate,isLoading: updateContactLoading } = useUpdateContact();
  const { isFetching: tagsLoading } = useGetTagsByCategory();

  const dispatch: AppDispatch = useDispatch();

  const onFinish = (values: any) => {
    const data = {
      ...values,
      tags: values.tags.flatMap((tag: { category: string | null; tags: { value: string }[] }) => {
        return {
          category:tag.category === "uncategorized" || !tag.category ? null : tag.category,
          tags: tag.tags.map((tag) => {
            return tag.value;
          }),
        };
      }),
    }
    updateContactMutate(
      data,
      {
        onSuccess: (data) => {
          setIsModalOpen(false);
          dispatch(updateContact(data));
          messageApi.success("Contact updated successfully");
        },
      }
    );
  };


  return (
    <ModalWrapper title="Update Contact" isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <AddContactForm
        defaultValues={editData}
        setIsModalOpen={setIsModalOpen}
        tags={tags}
        isTagsFetching={tagsLoading}
        onSubmit={(values: any) => {
          onFinish(values);
        }}
        categories={categories}
        isLoadingAddContact={updateContactLoading}
        messageApi={null}
      />
    </ModalWrapper>
  );
};

export default ExistingContactModal;
