import React from "react";
import ModalWrapper from "../generic/modal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { AddContactForm } from "@data-phone/react-generic";
import { useAddContact } from "../../hooks/apis/contacts.api";
import { addContact } from "../../store/slices/contact.slice";
import { useMessageToast } from "../Layout/DefaultLayout";
import { useGetTagsByCategory } from "../../hooks/apis/tags.api";

const AddContactModal = ({ setIsModalOpen, isModalOpen ,initialNumber}: { setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>; isModalOpen: boolean, initialNumber: string }) => {
  const dispatch: AppDispatch = useDispatch();
  const { messageApi } = useMessageToast();

  const { data: tags } = useSelector((state: RootState) => state.tags);
  const { data: categories } = useSelector((state: RootState) => state.categories);

  const { mutate: addContactHandler, isLoading: addContactLoading } = useAddContact();
  const { isFetching: tagsLoading } = useGetTagsByCategory();

  const onFinish = (values: any) => {
    addContactHandler(
      {
        ...values,
        tags: values.tags.flatMap((tag: { category: string | null; tags: { value: string }[] }) => {
          return {
            category: tag.category === "uncategorized" ? null : tag.category,
            tags: tag.tags.map((tag) => {
              return tag.value;
            }),
          };
        }),
      },
      {
        onSuccess: (data) => {
          setIsModalOpen(false);
          dispatch(addContact(data));
          messageApi.success("Contact added successfully");
        },
      }
    );
  };

  return (
    <ModalWrapper title="Add Contact" isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <AddContactForm initialNumbers={[initialNumber]} onSubmit={onFinish} isLoadingAddContact={addContactLoading} messageApi={null} setIsModalOpen={setIsModalOpen} categories={categories} tags={tags} isTagsFetching={tagsLoading} />
    </ModalWrapper>
  );
};

export default AddContactModal;
