import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Avatar, Button, Checkbox, Flex, List, Popover, Typography } from "antd";
import { MdOutlinePhone } from "react-icons/md";
import { formatPhoneNumber } from "../../utils/phoneLib";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { generateNameSpaceColor } from "../../utils/color";
import { useState } from "react";

const avatarStyle: React.CSSProperties = {
  verticalAlign: "middle",
  color: "black",
  fontWeight: "bold",
};

export const SelectedContacts = ({ contacts, setSelectedContacts, setIsModal, type }: { contacts: string[]; setSelectedContacts: Function; setIsModal: Function; type: "valid" | "invalid" }) => {
  const { data: allContacts } = useSelector((state: RootState) => state.contacts);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  return (
    <Flex style={{ padding: "10px 10px", maxHeight: "500px", overflow: "auto" }} vertical>
      <Checkbox
        onChange={() => {
          if (selectedRowKeys.length === contacts.length) {
            setSelectedRowKeys([]);
          } else {
            setSelectedRowKeys(contacts);
          }
        }}
        checked={selectedRowKeys.length === contacts.length}
      >
        Select All
      </Checkbox>
      <List
        dataSource={allContacts.filter((contact) => contacts.includes(contact.id as string))}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <Flex style={{ width: "100%" }} justify="space-between" align="center" gap={"10px"}>
              <Flex gap={"10px"} align={"center"}>
                <Checkbox
                  checked={selectedRowKeys.includes(item.id as string)}
                  onChange={() => {
                    setSelectedRowKeys((prev) => {
                      if (prev.includes(item.id as string)) {
                        return prev.filter((key) => key !== item.id);
                      } else {
                        return [...prev, item.id as string];
                      }
                    });
                  }}
                />
                <Avatar style={{ ...avatarStyle, background: generateNameSpaceColor(item.first_name || "") }} size="large">
                  {item.first_name?.charAt(0).toUpperCase() || ""}
                </Avatar>
                <Typography.Text style={{ fontWeight: "500" }}>
                  {item.first_name || ""} {item.middle_name || ""} {item.last_name || ""}{" "}
                </Typography.Text>
              </Flex>
              <Flex gap={"10px"} align={"center"}>
                {" "}
                <MdOutlinePhone color="blue" size={20} />
                <Typography.Text strong>{formatPhoneNumber(item.phones[0]?.phone_number)}</Typography.Text>
                {item.phones.length > 1 ? (
                  <Popover
                    content={item.phones.map((phone) => (
                      <Flex gap={"10px"} vertical>
                        {" "}
                        <Typography.Text>{formatPhoneNumber(phone.phone_number)}</Typography.Text>
                      </Flex>
                    ))}
                  >
                    <HiOutlineDotsHorizontal cursor={"pointer"} />
                  </Popover>
                ) : null}
              </Flex>
            </Flex>
          </List.Item>
        )}
      />
      <Button
        type="primary"
        onClick={() => {
          setSelectedContacts((prev: string[]) => {
            return prev.filter((key) => !selectedRowKeys.includes(key));
          });
          setIsModal(false);
        }}
        disabled={selectedRowKeys.length === 0}
      >
        Remove Selected
      </Button>
    </Flex>
  );
};
