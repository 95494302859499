import { Content } from "antd/es/layout/layout";
import { ConversationHeader } from "./Header.conversations";
import { useState } from "react";
import ConversationList from "./conversations.list";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { SelectedDid } from "../../../store/slices/userDids.slice";

const ConversationContainer = ({
  setIsNewConversation,
  updateConversation
}: {
  setIsNewConversation: Function;
  updateConversation: Function
}) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const { selectedDid }: { selectedDid: SelectedDid } = useSelector(
    (state: RootState) => state.userDids
  );
  const [convsersationsFilter, setConversationsFilter] = useState<{
    unread: boolean;
    assigned: boolean;
    unresolved: boolean;
    resolved: boolean;
    unassigned: boolean;
    assigned_user: boolean
  }>({
    unread: false,
    assigned: false,
    unresolved: false,
    resolved: false,
    unassigned: false,
    assigned_user: false,
  });

  const ContainerContentStyle: React.CSSProperties = {
    height: "100%",
    borderRight: "1px solid rgb(223, 226, 232)",
  };

  return (
    <Content style={ContainerContentStyle}>
      <ConversationHeader
        setIsNewConversation={setIsNewConversation}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setConversationsFilter={setConversationsFilter}
        convsersationsFilter={convsersationsFilter}
      />
      {selectedDid && (
        <ConversationList
          key={selectedDid}
          updateConversation={updateConversation}
          setIsNewConversation={setIsNewConversation}
          selectedDid={selectedDid}
          searchInput={searchInput}
          convsersationsFilter={convsersationsFilter}
        />
      )}
    </Content>
  );
};

export default ConversationContainer;
