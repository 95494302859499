import { Button, Flex, Select, Typography } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { TemplatesTable } from "../../components/Admin/Tables/Templates";
import { usePermissions } from "../../hooks/permissions";
import TemplateForm from "../../components/Form/Template";
import ModalWrapper from "../../components/generic/modal";

export const Templates = () => {
  const { hasPermission } = usePermissions();

  const { data: userData } = useSelector((state: RootState) => state.user);
  const { accounts } = useSelector((state: RootState) => state.admin);

  const [addTemplate, setAddTemplate] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<string | null | undefined>(hasPermission("sms", ["readTemplates:any", "readTemplates:account"]) ? userData?.accountID : null);

  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div style={{ padding: "20px" }}>
      <Flex justify="space-between" align="center" style={{ padding: "10px" }}>
        <Typography.Title>{`Templates`}</Typography.Title>{" "}
        {
          <Flex gap={10}>
            <Button
              type="primary"
              onClick={() => {
                setAddTemplate(true);
              }}
            >
              Add Template
            </Button>
          </Flex>
        }
      </Flex>

      {hasPermission("sms", "readTemplates:any") && (
        <Select
          showSearch
          placeholder="Select Account"
          value={selectedAccount}
          onChange={(value) => {
            setSelectedAccount(value);
          }}
          filterOption={filterOption}
          options={accounts.map((account) => {
            return { label: account.name, value: account.id };
          })}
        />
      )}

      <div style={{ marginTop: "20px" }}>
        {addTemplate && (
          <ModalWrapper isModalOpen={addTemplate} setIsModalOpen={setAddTemplate} title="Add Template">
            <TemplateForm setIsModalOpen={setAddTemplate} account_id={selectedAccount} editData={null} />
          </ModalWrapper>
        )}
        <TemplatesTable account_id={selectedAccount} searchInput="" />
      </div>
    </div>
  );
};
