import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { TemplateType, setTemplates, updateTemplateSlice } from "../../store/slices/templates/templates.slice";
import { api } from "@data-phone/react-resource-server-auth";
import { setUserTemplatesSlice } from "../../store/slices/templates/user_templates.slice";

export interface TemplateDid {
  did: string;
  template_id: string;
  account_id: string;
}

export interface TemplateUser {
  user_id: string;
  template_id: string;
  account_id: string;
}

const fetchTemplates = async (account_id?: string | null): Promise<TemplateType[]> => {
  const response: AxiosResponse<TemplateType[]> = await api.get(`${process.env.REACT_APP_SMS_API_URL}/templates${account_id ? `?account_id=${account_id}` : ''}`);
  return response.data;
};
const addTemplateApi = async (data: Partial<TemplateType>, dispatch: AppDispatch): Promise<TemplateType> => {
  const response: AxiosResponse<TemplateType> = await api.post(`${process.env.REACT_APP_SMS_API_URL}/templates`, data);
  return response.data;
};
const updateTemplateById = async (id: string, data: TemplateType, dispatch: AppDispatch): Promise<TemplateType> => {
  const response = await api.put<{ data: TemplateType }>(`${process.env.REACT_APP_SMS_API_URL}/templates/${id}`, data);
  dispatch(updateTemplateSlice(data));
  return response.data;
};

const getTemplateDids = async (id: string) => {
  const response = await api.get(`${process.env.REACT_APP_SMS_API_URL}/templates/dids/${id}`);
  return response.data;
};

const getUserTemplates = async (id: string) => {
  const response = await api.get(`${process.env.REACT_APP_SMS_API_URL}/templates/users/${id}`);
  return response.data;
};

const deleteTemplateDid = async ({ did, template_id }: { did: string; template_id: string }) => {
  const response = await api.delete(`${process.env.REACT_APP_SMS_API_URL}/templates/dids/${encodeURIComponent(did)}/${template_id}`);
  return response;
};

const assignDidApi = async (data: { dids: string[]; template_id: string }) => {
  const response = await api.post(`${process.env.REACT_APP_SMS_API_URL}/templates/dids`, data);
  return response;
};

export const deleteTemplateById = async (id: string) => {
  return await api.delete(`${process.env.REACT_APP_SMS_API_URL}/templates/${id}`);
};

export const getTemplateById = async (id: string) => {
  const response = await api.get(`${process.env.REACT_APP_SMS_API_URL}/templates/${id}`);
  return response.data;
};

export const useGetTemplates = (account_id?: string | null) => {
  const dispatch: AppDispatch = useDispatch();
  return useQuery<any[], Error>("templates", () => fetchTemplates(account_id), {
    enabled: false,
    onError: (error) => {
      console.log("failed", error);
    },
    onSuccess: (data) => {
      dispatch(setTemplates(data))
    }
  });
};

export const useAddTemplate = () => {
  const dispatch: AppDispatch = useDispatch();
  return useMutation((data: Partial<TemplateType>) => addTemplateApi(data, dispatch));
};

export const useUpdateTemplate = (id: string) => {
  const dispatch: AppDispatch = useDispatch();
  return useMutation((data: TemplateType) => updateTemplateById(id, data, dispatch), {
    onSuccess: () => {
      console.log("success");
    },
    onError: () => {
      console.log("failed");
    },
  });
};

export const useDeleteTemplate = () => {
  return useMutation((id: string) => deleteTemplateById(id));
};

export const useGetTemplateById = (id: string) => {
  return useQuery<TemplateType, Error>("template", () => getTemplateById(id), {
    enabled: false,
    onError: (error) => {
      console.log("failed", error);
    },
  });
};

export const useGetTemplateDids = (id: string) => {
  return useQuery<TemplateDid[], Error>("templateDids", () => getTemplateDids(id), {
    enabled: false,
    onError: (error) => {
      console.log("failed", error);
    },
  });
};

export const useGetTemplateUsers = (id: string) => {
  const dispatch: AppDispatch = useDispatch();
  return useQuery<TemplateUser[], Error>("userTemplates", () => getUserTemplates(id), {
    enabled: false,
    onError: (error) => {
      console.log("failed", error);
    },
    onSuccess: (data) => {
      dispatch(setUserTemplatesSlice(data));
    },
  });
};

export const useAssignTemplateDid = () => {
  return useMutation((data: { dids: string[]; template_id: string }) => assignDidApi(data));
};

export const useDeleteTemplateDid = () => {
  return useMutation((data: { did: string; template_id: string }) => deleteTemplateDid(data));
};
