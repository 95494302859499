import axios, { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { AppDispatch } from "../../store/store";
import { useDispatch } from "react-redux";
import {  setContacts } from "../../store/slices/contact.slice";
import { api } from "@data-phone/react-resource-server-auth";
import { Contact } from "@data-phone/react-generic/dist/components/Forms/Contacts";

interface responseType {
  data: Contact[];
  status: string;
}


export interface contactQuery {
  updated_at: string;
}

const fetchContactsApi = async (query?: contactQuery) => {
  const response = await api.get(
    `${process.env.REACT_APP_CONTACT_API_URL}?${query?.updated_at ? `since=${query.updated_at}` : ""}`,
  );
  return response.data;
}

const addContactApi = async (data: any) => {
  const response: AxiosResponse<responseType> = await api.post(
    `${process.env.REACT_APP_CONTACT_API_URL}`,
    data
  );
  return response.data;
}

const updateContactApi = async (data: any) => {
  const response: AxiosResponse<responseType> = await api.put(
    `${process.env.REACT_APP_CONTACT_API_URL}/${data.id}`,
    data
  );
  return response.data;
}

export const useGetContacts = (query?: contactQuery) => {
  const dispatch: AppDispatch = useDispatch();
  return useQuery<any[], Error>(
    "contacts",
    () => fetchContactsApi(query),
    {
      enabled: false,
      onError: (error) => {
        console.log("failed", error);
      },
      onSuccess: (data) => {
        dispatch(setContacts(data))
      }
    }
  );
};

export const useAddContact = () => {

  return useMutation((data: any) => addContactApi(data), {
    onSuccess: () => {
      console.log("success");
    },
    onError: (error:any) => {
      console.log("error", error.response.data.error)
    },
  });
}

export const useUpdateContact = () => {
  return useMutation((data: any) => updateContactApi(data), {
    onSuccess: () => {
      console.log("success");
    },
    onError: (error:any) => {
      console.log("error", error.response.data.error)
    },
  });
}