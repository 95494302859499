import { Descriptions, DescriptionsProps, Typography } from "antd";
import { TemplateType } from "../../../store/slices/templates/templates.slice";
import { usePermissions } from "../../../hooks/permissions";

export const TemplateInformation = ({ template }: { template: TemplateType }) => {
  const { hasPermission } = usePermissions();

  const templateItems: DescriptionsProps["items"] = [
    {
      label: "Name",
      span: 2,
      children: <Typography.Text>{template?.name}</Typography.Text>,
    },
    ...(hasPermission("sms", ["readTemplates:any", "readTemplates:account"])
      ? [
          {
            label: "Visibility",
            span: 2,
            children: <Typography.Text>{template?.visibility === "did" ? "Numbers" : template?.visibility}</Typography.Text>,
          },
        ]
      : []),
    {
      label: "description",
      span: 2,
      children: <Typography.Text>{template?.description}</Typography.Text>,
    },
    {
      label: "body",
      span: 2,
      children: <Typography.Text>{template?.body}</Typography.Text>,
    },
  ];

  return <Descriptions layout="vertical" bordered items={templateItems} />;
};
