import { Contact } from "@data-phone/react-generic/dist/components/Forms/Contacts";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface Address {
  address1: string;
  address2?: string;
  city?: string;
  ZIP?: string;
}

export interface Phone {
  phone_number: string;
}

export interface Email {
  email: string;
}


export interface ContactsState {
  data: Contact[];
}

const initialState: ContactsState = {
  data: [],
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContacts: (state, action: PayloadAction<Contact[]>) => {
      if(action.payload.length > 0){
        const contacts = state.data.filter((item) => item.id !== action.payload[0].id)
        state.data = [...contacts, ...action.payload]
      }
    },
    addContact: (state, action: PayloadAction<any>) => {
      state.data.push(action.payload)
    },
    updateContact: (state, action: PayloadAction<any>) => {
      state.data = state.data.map((contact) => contact.id == action.payload.id ? action.payload : contact)  
    }
  },
});

export const { setContacts,addContact ,updateContact} = contactsSlice.actions;

export default contactsSlice.reducer;