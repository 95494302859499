import { initializeApp } from "firebase/app";
import { useEffect, useRef, useState } from "react";
import { getPushNotificationConfig, PushNotificationConfig, registerNotificationToken } from "../apis/notifications.api";
import { getMessaging, getToken, Messaging, onMessage } from "firebase/messaging";


export const usePushNotificationRegister = () => {
  const [pushNotificationConfig, setPushNotificationConfig] = useState<PushNotificationConfig | null>(null);
  const [messaging, setMessaging] = useState<Messaging | null>(null);

  const firebaseApp = useRef({
    initializeApp: initializeApp,
  });



  const registerToken = async (messaging: Messaging) => {
    if (!pushNotificationConfig?.vapidKey) {
      console.error("Vapid Key is missing");
      return;
    }

    try {
      const currentToken = await getToken(messaging, { vapidKey: pushNotificationConfig.vapidKey });
      if (currentToken) {
        await registerNotificationToken(currentToken);
        console.log("Token registered successfully:", currentToken);
      } else {
        console.log("No registration token available. Request permission to generate one.");
      }
    } catch (err) {
      console.error("Error retrieving token: ", err);
    }
  };

  const registerServiceWorker = async (config: Omit<PushNotificationConfig, "vapidKey">) => {
    try {
      if ("serviceWorker" in navigator) {
        const registration = await navigator.serviceWorker.register(`/firebase-messaging-sw.js`);
        console.log("Service worker registration successful with scope:", registration.scope);

        if (registration.active) {
          console.log("Service worker active:", registration.active);
          registration.active.postMessage(config);
        }
      }
    } catch (error) {
      console.error("Error registering service worker:", error);
    }
  };

  useEffect(() => {
    const initFirebaseMessaging = async () => {
      try {
        const config = await getPushNotificationConfig();
        if (firebaseApp.current) {
          const app = firebaseApp.current.initializeApp(config);
          const messagingInstance = getMessaging(app);
          onMessage(messagingInstance, (payload) => {
            console.log("Message received in the background: ", payload);
          })
        }
        setPushNotificationConfig(config);
      } catch (error) {
        console.error("Failed to fetch push notification config:", error);
      }
    };

    initFirebaseMessaging();
  }, []);

  useEffect(() => {
    if (messaging) {
      registerToken(messaging);
    }
  }, [messaging]);

  useEffect(() => {
    if (pushNotificationConfig) {
      registerServiceWorker(pushNotificationConfig);
    }
  }, [pushNotificationConfig]);

  return { pushNotificationConfig };
};
