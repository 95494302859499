import { Empty, Flex, List } from "antd";
import { Conversation } from "../../../interfaces/conversations.type";

import ConversationsCard from "./Conversations.card";
import { SelectedDid } from "../../../store/slices/userDids.slice";
import useGetConversations from "../../../hooks/apis/conversations.api";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../generic/Loader";
import { useEffect } from "react";

/**
 * ConversationsList component - displays a list of dids conversation
 *
 * @param {RootState} state - Redux state
 * @returns {JSX.Element} - Rendered ConversationList component
 */
const ConversationList = ({
  searchInput,
  selectedDid,
  setIsNewConversation,
  convsersationsFilter,
  updateConversation,
}: {
  searchInput: string;
  selectedDid: SelectedDid;
  setIsNewConversation: Function;
  convsersationsFilter: {
    unread: boolean;
    assigned: boolean;
    unresolved: boolean;
    resolved: boolean;
    assigned_user: boolean;
    unassigned: boolean;
  };
  updateConversation: Function;
}): JSX.Element => {
  const { data, selectedConversation } = useSelector(
    (state: RootState) => state.conversation
  );
  const { isAdminView, selectedAccount } = useSelector((state: RootState) => state.admin);

  const { data: user } = useSelector(
    (state: RootState) => state.user
  );

  const filteredData = data
    .filter((conversation: Conversation) => conversation.did == selectedDid)
    .filter((conversation) => {
      return (
        (!convsersationsFilter.unread || conversation.total_unread > 0 || conversation.unread === true) &&
        (!convsersationsFilter.assigned || conversation.assigned_user !== null) &&
        (!convsersationsFilter.unresolved || !conversation.resolved) &&
        (!convsersationsFilter.resolved || conversation.resolved) &&
        (!convsersationsFilter.unassigned || conversation.assigned_user == null) &&
        (!convsersationsFilter.assigned_user || conversation.assigned_user == user?.id)
      );

      return true;
    });
  const { fetchNextPage, isLoading, refetch } =
    useGetConversations({
      did: selectedDid,
      offset: 0,
      limit: 20,
      unread: convsersationsFilter.unread,
      assigned: convsersationsFilter.assigned,
      unresolved: convsersationsFilter.unresolved,
      unassigned: convsersationsFilter.unassigned,
      resolved: convsersationsFilter.resolved,
      assigned_user: convsersationsFilter.assigned_user ? user?.id : null,
      account: isAdminView ? selectedAccount : null,
    });


  useEffect(() => {
    refetch();
  }, [selectedDid, convsersationsFilter]);

  const spinStyle: React.CSSProperties = {
    width: "100%",
    height: "calc(100% - 80px)",
  };

  if (isLoading) {
    return (
      <Flex style={spinStyle} justify="center" align="center">
        <Loader />
      </Flex>
    );
  }

  if (!isLoading && filteredData.length == 0) {
    return (
      <Flex style={spinStyle} justify="center" align="center">
        {<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </Flex>
    );
  }

  return (
    <List
      style={{ height: "calc(100% - 80px)", overflow: "auto" }}
      itemLayout="horizontal"
      id="scrollableDiv"
      dataSource={filteredData}
    >
      <InfiniteScroll
        dataLength={filteredData.length}
        next={() => {
            fetchNextPage();
        }}
        key={"scrollableDiv"}
        hasMore={true}
        loader={""}
        scrollableTarget="scrollableDiv"
      >
        {filteredData
          .sort(
            (a, b) =>
              new Date(b.last_message_time).getTime() -
              new Date(a.last_message_time).getTime()
          )
          .map((conversation: Conversation, index: number) => (
            <ConversationsCard
              updateConversation={updateConversation}
              isSelected={
                selectedConversation
                  ? selectedConversation.participant ===
                  conversation.participant
                  : false
              }
              assigned={conversation.assigned_user}
              setIsNewConversation={setIsNewConversation}
              key={index}
              index={index}
              conversation={conversation}
            />
          ))}
      </InfiniteScroll>
    </List>
  );
};

export default ConversationList;
