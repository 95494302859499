import dayjs, { Dayjs } from "dayjs";

export const disabledTime = (current: Dayjs | null) => {
    if (!current) return {};

    const now = dayjs();
    const isToday = current.isSame(now, 'day');

    if (isToday) {
        return {
            disabledHours: () => Array.from({ length: now.hour() }, (_, i) => i),
            disabledMinutes: () =>
                current.hour() === now.hour() ? Array.from({ length: now.minute() + 1 }, (_, i) => i) : [],
            disabledSeconds: () =>
                current.hour() === now.hour() && current.minute() === now.minute()
                    ? Array.from({ length: now.second() }, (_, i) => i)
                    : [],
        };
    }

    return {};
};
