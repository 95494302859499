import { useEffect, useState } from "react";
import { Button, Form, type FormProps, Input, Radio, Select, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useAddTemplate, useGetTemplateDids, useGetTemplateUsers, useUpdateTemplate } from "../../hooks/apis/templates.api";
import { addTemplateSlice, TemplateType, updateTemplateSlice } from "../../store/slices/templates/templates.slice";
import { usePermissions } from "../../hooks/permissions";
import { formatPhoneNumber } from "../../utils/phoneLib";
import { useGetAllUsers } from "../../hooks/apis/user.api";
import { useGetDidsByAccountId } from "../../hooks/apis/dids.api";
import { useMessageToast } from "../Layout/DefaultLayout";

type FieldType = {
  name: string;
  body: string;
  description?: string;
  visibility: "public" | "did" | "user" | "private";
  dids?: string[];
  users?: string[];
  account_id?: string;
};

const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const TemplateForm = ({ setIsModalOpen, editData, account_id }: { setIsModalOpen: Function; editData: TemplateType | null; account_id: string | undefined | null }) => {
  const dispatch: AppDispatch = useDispatch();
  const { messageApi } = useMessageToast();

  const { dids, users } = useSelector((state: RootState) => state.admin);
  const { data: userData } = useSelector((state: RootState) => state.user);
  const { data: templateUsers } = useSelector((state: RootState) => state.templateUsers);
  const { data: templateDids } = useSelector((state: RootState) => state.templateDids);

  const { mutate: addTemplate, isLoading, isSuccess } = useAddTemplate();
  const { mutate: updateTemplate, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, data: dataUpdate } = useUpdateTemplate(editData?.id ?? "");
  const { refetch: refetchTemplateDids, isFetching: isLoadingTemplateDids } = useGetTemplateDids(editData?.id ?? "");
  const { refetch: refetchTemplateUsers, isFetching: isLoadingTemplateUsers } = useGetTemplateUsers(editData?.id ?? "");
  const { refetch: refetchDids } = useGetDidsByAccountId(account_id);
  const { refetch: refetchUsers } = useGetAllUsers();

  const [form] = Form.useForm();
  const { hasPermission } = usePermissions();
  const [visibility, setVisibility] = useState<"public" | "did" | "user" | "private">(editData ? editData.visibility : !account_id ? "private" : "public");

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if (dids.length === 0 && hasPermission("sms", ["createTemplates:account", "createTemplates:any"])) {
      refetchDids();
    }
    if (users.length === 0 && hasPermission("sms", ["createTemplates:account", "createTemplates:any"])) {
      refetchUsers();
    }
  }, [hasPermission]);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue(editData);
      setVisibility(editData.visibility);
    }
    if (editData && editData.visibility === "did" && hasPermission("sms", ["createTemplates:account", "createTemplates:any"])) {
      refetchTemplateDids();
    }

    if (editData && editData.visibility === "user" && hasPermission("sms", ["createTemplates:account", "createTemplates:any"])) {
      refetchTemplateUsers();
    }
  }, [editData]);

  useEffect(() => {
    if (
      templateDids &&
      editData &&
      editData.visibility === "did" &&
      templateDids.some((templateDid) => templateDid.template_id === editData.id) &&
      hasPermission("sms", ["createTemplates:account", "createTemplates:any"])
    ) {
      form.setFieldsValue({ dids: templateDids.filter((templateDid) => templateDid.template_id === editData.id).map((templateDid) => templateDid.did) });
    }

    if (
      templateUsers &&
      editData &&
      templateUsers.some((templateusr) => templateusr.template_id === editData.id) &&
      editData.visibility === "user" &&
      hasPermission("sms", ["createTemplates:account", "createTemplates:any"])
    ) {
      form.setFieldsValue({ users: templateUsers.filter((templateUser) => templateUser.template_id === editData.id).map((templateUser) => templateUser.user_id) });
    }
  }, [templateDids, templateUsers]);

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    if (!userData) return;

    if (editData) {
      let updateTemplateData = { ...values, visibility };

      if (hasPermission("sms", ["updateTemplates:any"])) {
        updateTemplateData.account_id = account_id ? account_id : userData.accountID;
      } else if (hasPermission("sms", ["updateTemplates:account"])) {
      }

      updateTemplate({
        ...updateTemplateData,
        id: editData.id,
      });
      return;
    }
    addTemplate(
      { ...values, account_id: account_id ? account_id : userData?.accountID, visibility },
      {
        onSuccess: (data) => {
          dispatch(addTemplateSlice(data));
        },
      }
    );
  };

  useEffect(() => {
    if (isSuccess) {
      messageApi.success("Template created successfully");
      form.resetFields();
      setIsModalOpen(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessUpdate) {
      dispatch(updateTemplateSlice(dataUpdate));
      messageApi.success("Template updated successfully");
      form.resetFields();
      setIsModalOpen(false);
    }
  }, [isSuccessUpdate]);

  return (
    <Form style={{ padding: "10px" }} name="basic" form={form} labelCol={{ span: 4 }} initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
      <Form.Item<FieldType> label="Name" name="name" rules={[{ required: true, message: "Please input template name!" }]}>
        <Input placeholder="Template Name" />
      </Form.Item>
      <Form.Item<FieldType> label="Description" name="description">
        <Input.TextArea placeholder="Template Description" />
      </Form.Item>
      <Form.Item<FieldType> name="body" label="Body" rules={[{ required: true, message: "Please input your text!" }]}>
        <Input.TextArea placeholder="Template Body" />
      </Form.Item>

      {hasPermission("sms", ["createTemplates:any", "createTemplates:account"]) && (
        <Form.Item name="visibility" label="Visibility">
          <Radio.Group name="visibility" defaultValue={visibility} onChange={(e) => setVisibility(e.target.value)}>
            <Radio disabled={!hasPermission("sms", ["createTemplates:any", "createTemplates:account"])} value="public">
              Public
            </Radio>
            <Radio disabled={!hasPermission("sms", ["createTemplates:any", "createTemplates:account"])} value="did">
              Numbers
            </Radio>
            <Radio value="user">User</Radio>
            <Radio value="private">Private</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {hasPermission("sms", ["createTemplates:any", "createTemplates:account"]) && visibility === "did" && (
        <Form.Item<FieldType> name="dids" label="Numbers" rules={[{ required: true, message: "Please select numbers to add" }]}>
          <Select
            showSearch
            placeholder="Select Numbers"
            mode="multiple"
            loading={isLoadingTemplateDids && templateDids.filter((templateDid) => templateDid.template_id === editData?.id).length === 0}
            disabled={isLoadingTemplateDids && templateDids.filter((templateDid) => templateDid.template_id === editData?.id).length === 0}
            filterOption={filterOption}
            optionFilterProp="children"
            options={
              dids.map((did) => {
                return {
                  label: formatPhoneNumber(did.did),
                  value: did.did,
                };
              }) as { label: string; value: string }[]
            }
          />
        </Form.Item>
      )}

      {(hasPermission("sms", ["createTemplates:any", "createTemplates:account"]) && visibility === "user") && (
        <Form.Item<FieldType> name="users" label="Users" rules={[{ required: true, message: "Please select users to add" }]}>
          <Select
            showSearch
            loading={isLoadingTemplateUsers && templateUsers.filter((templateUser) => templateUser.template_id === editData?.id).length === 0}
            disabled={isLoadingTemplateUsers && templateUsers.filter((templateUser) => templateUser.template_id === editData?.id).length === 0}
            placeholder="Select Users"
            mode="multiple"
            filterOption={filterOption}
            optionFilterProp="children"
            options={users
              .filter((usr) => usr.account_id === account_id)
              .map((user) => {
                return {
                  label: `${user.first_name} ${user.last_name}`,
                  value: user.id,
                };
              })}
          />
        </Form.Item>
      )}

      <Form.Item>
        <Button style={{ width: "100%" }} type="primary" disabled={isLoading || isLoadingUpdate} loading={isLoading || isLoadingUpdate} htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TemplateForm;
