import { Card, Col, Row, Typography } from "antd";

export const StaticCard: React.FC<{
  title: string;
  value: number;
  className: string;
  icon: React.ReactNode | null;
}> = ({ title, value, className, icon }) => {
  return (
    <Card bordered={false} className="criclebox ">
      <div className="number">
        <Row align="middle" gutter={[24, 0]}>
          <Col xs={18}>
            <span>{title.toLocaleUpperCase()}</span>
            <Typography.Title className={className} level={3}>
              {value}
            </Typography.Title>
          </Col>
          <Col xs={6}>
            <div className="icon-box">{icon}</div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
