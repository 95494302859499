import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TemplateDid } from "../../../hooks/apis/templates.api";

interface State {
  data: TemplateDid[];
}

const initialState: State = {
  data: [],
};

const templateDidSlice = createSlice({
  name: "templateDids",
  initialState,
  reducers: {
    setTemplateDids: (state, action: PayloadAction<TemplateDid[]>) => {
      state.data = [...state.data.filter((item) => item.template_id !== action.payload[0].template_id), ...action.payload];
    },
  },
});

export const { setTemplateDids } = templateDidSlice.actions;
export default templateDidSlice.reducer;
