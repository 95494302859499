import { api } from "@data-phone/react-resource-server-auth"
import { useMutation, useQuery } from "react-query"
import { AxiosResponse } from "axios"
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { setTagCategoriesSlice, TagCategory } from "../../store/slices/categories.slice";

interface responseType {
    data: TagCategory[];
    status: string;
}
const getCategoriesApi = async () => {
    const response = await api.get(`
        ${process.env.REACT_APP_CONTACT_API_URL}/category`)
    return response.data
}




export const useGetCategories = () => {
    const dispatch: AppDispatch = useDispatch();
    return useQuery<TagCategory[], Error>(
        "categories",
        getCategoriesApi, {
        onSuccess(data) {
            dispatch(setTagCategoriesSlice(data))
        },
        enabled: false
    }
    )
}

