import { Avatar, Button, Flex, Popover, Table, TableProps, Tag, Tooltip, Typography } from "antd";
import { MdOutlinePhone } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { formatPhoneNumber } from "../../utils/phoneLib";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { generateNameSpaceColor } from "../../utils/color";
import { Contact } from "@data-phone/react-generic/dist/components/Forms/Contacts";

const avatarStyle: React.CSSProperties = {
  verticalAlign: "middle",
  color: "black",
  fontWeight: "bold",
};
export const ContactsTable = ({
  contacts,
  setSelectedContacts,
  selectedContacts,
  searchText,
  selectedTags,
  isLoadingContacts,
}: {
  contacts: Contact[];
  setSelectedContacts: Function;
  selectedContacts: React.Key[];
  searchText: string;
  selectedTags: string[];
  isLoadingContacts: boolean;
}) => {
  const { data: tags } = useSelector((state: RootState) => state.tags);

  const columns: TableProps<Contact>["columns"] = [
    {
      key: "name",
      title: "Name",
      className: "contact-column",
      render: (text, record) => (
        <Flex align="center" gap={10}>
          <Avatar style={{ ...avatarStyle, background: generateNameSpaceColor(record.first_name || "") }} size="large">
            {record.first_name?.charAt(0).toUpperCase() || ""}
          </Avatar>
          <Flex vertical>
            <Typography.Text style={{ fontWeight: "500" }}>
              {record.first_name || ""} {record.middle_name || ""} {record.last_name || ""}{" "}
            </Typography.Text>
            <Typography.Text type="secondary">{record.emails.length > 0 ? record.emails[0].email : ""}</Typography.Text>
          </Flex>
        </Flex>
      ),
    },
    {
      title: "Phone",
      className: "contact-column",
      align: "left",
      dataIndex: "phone",
      key: "phone",
      render: (text, { phones }) => (
        <Flex gap={"10px"} align={"center"}>
          {" "}
          <MdOutlinePhone color="blue" size={20} />
          <Typography.Text strong>{formatPhoneNumber(phones[0]?.phone_number)}</Typography.Text>
          {phones.length > 1 ? (
            <Popover
              content={phones.map((phone) => (
                <Flex gap={"10px"} vertical>
                  {" "}
                  <Typography.Text>{formatPhoneNumber(phone.phone_number)}</Typography.Text>
                </Flex>
              ))}
            >
              <HiOutlineDotsHorizontal cursor={"pointer"} />
            </Popover>
          ) : null}
        </Flex>
      ),
    },
    {
      title: "Tags",
      className: "contact-column",
      align: "left",
      dataIndex: "tags",
      key: "tags",
      render: (_, { tags: contactTag }) => (
        <Flex>
          {contactTag
            ?.filter((tag, index) => index < 3)
            .sort((a, b) => {
              const tagA = tags.find((t) => t.name === a.tag)?.name;
              const tagB = tags.find((t) => t.name === b.tag)?.name;
              if (tagA && tagB) {
                if (tagA < tagB) {
                  return -1;
                }
                if (tagA > tagB) {
                  return 1;
                }
                return 0;
              }
              return 0;
            })
            .map((tag) => {
              return (
                <Tag className="text-ellipsis" color="blue">
                  <Tooltip title={tags.find((t) => t.name === tag.tag)?.name}>
                    <Typography.Text>{tags.find((t) => t.name === tag.tag)?.name}</Typography.Text>
                  </Tooltip>
                </Tag>
              );
            })}
          {contactTag && contactTag?.length > 3 && (
            <Popover
              content={contactTag
                .sort((a, b) => {
                    const tagA = tags.find((t) => t.name === a.tag)?.name;
                    const tagB = tags.find((t) => t.name === b.tag)?.name;
                    if (tagA && tagB) {
                      if (tagA < tagB) {
                        return -1;
                      }
                      if (tagA > tagB) {
                        return 1;
                      }
                      return 0;
                    }
                    return 0;
                  })
                .map((tag) => {
                  return (
                    <Tag color="blue">
                      <Tooltip title={tags.find((t) => t.name === tag.tag)?.name}>
                        <Typography.Text>{tags.find((t) => t.name === tag.tag)?.name}</Typography.Text>
                      </Tooltip>
                    </Tag>
                  );
                })}
            >
              <Button style={{ height: "25px" }} icon={<HiOutlineDotsHorizontal />}></Button>
            </Popover>
          )}
        </Flex>
      ),
    },
  ];

  return (
    <Table<Contact>
      
      sticky={{ offsetHeader: 0 }}
      scroll={{ y: 1000 }}
      rowSelection={{
        type: "checkbox",
        onChange(selectedRowKeys) {
          setSelectedContacts(selectedRowKeys);
        },
        selectedRowKeys: selectedContacts,
      }}
      className="contact-table"
      bordered={false}
      showHeader
      dataSource={contacts
        .filter((contact) => {
          if (searchText === "" && selectedTags.length === 0) {
            return true;
          } else if (searchText === "" && selectedTags.length > 0 && contact.tags) {
            return contact.tags.some((tag) => selectedTags.includes(tag.tag)) ;
          } else if (searchText !== "" && selectedTags.length === 0) {
            return (
              `${contact.first_name || ""} ${contact.middle_name || ""} ${contact.last_name || ""}`.toLowerCase().includes(searchText.toLowerCase()) ||
              contact.emails.some((email) => email.email.toLowerCase().includes(searchText.toLowerCase())) ||
              contact.phones.some((phone) => phone.phone_number.includes(searchText))
            );
          } else if (searchText !== "" && selectedTags.length > 0) {
            return (
              (`${contact.first_name || ""} ${contact.middle_name || ""} ${contact.last_name || ""}`.toLowerCase().includes(searchText.toLowerCase()) ||
                contact.emails.some((email) => email.email.toLowerCase().includes(searchText.toLowerCase())) ||
                contact.phones.some((phone) => phone.phone_number.includes(searchText))) &&
                contact.tags &&
              contact.tags.some((tag) => selectedTags.includes(tag.tag))
            );
          } else {
            return false;
          }
        })
        .map((contact) => {
          return {
            ...contact,
            key: contact.id,
          };
        })}
      loading={isLoadingContacts}
      pagination={false}
      columns={columns}
    ></Table>
  );
};
