import { Button, DatePicker, Flex, Input, List, Mentions, Popover, Segmented, Select, Switch, Tag, Tooltip, TreeSelect, Typography, UploadFile, UploadProps } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useGetUserDids } from "../../hooks/apis/userDids";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useMessageToast } from "../../components/Layout/DefaultLayout";
import { RcFile } from "antd/es/upload";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import TextArea from "antd/es/input/TextArea";
import { formatPhoneNumber, phoneNumberFormatE164, phoneNumberValidate } from "../../utils/phoneLib";
import { useSendBulkMessage } from "../../hooks/apis/bulkmessages.api";
import { getBase64 } from "../../utils/base64";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { parseCsv } from "../../utils/csv";
import { findTemplatePlaceHolders, replacePlaceholders } from "../../utils/templatePlaceHolders";
import { RiMenu2Fill } from "react-icons/ri";
import { useGetTemplates } from "../../hooks/apis/templates.api";
import { setSelectedAccount } from "../../store/slices/admin.slice";
import { FaFileCsv } from "react-icons/fa6";
import { RiContactsBook2Line } from "react-icons/ri";
import { FaRegCopy } from "react-icons/fa";
import { useGetContacts } from "../../hooks/apis/contacts.api";
import { IoSearch } from "react-icons/io5";
import { useGetCategories } from "../../hooks/apis/categories.api";
import { useGetTagsByCategory } from "../../hooks/apis/tags.api";
import dayjs from "dayjs";
import { ContactsTable } from "../../components/Tables/Contacts";
import ModalWrapper from "../../components/generic/modal";
import { SelectedContacts } from "../../components/Contacts/SelectedContacts.list";
import { disabledTime } from "../../utils/date-time-picker";
import { InvalidCsvList } from "../../components/Contacts/CsvList";
import { usePermissions } from "../../hooks/permissions";
import { TemplateType } from "../../store/slices/templates/templates.slice";
import { Contact } from "@data-phone/react-generic/dist/components/Forms/Contacts";

const rowStyle: React.CSSProperties = {
  height: "100%",
};

const iconStyle: React.CSSProperties = {
  height: "1rem",
  width: "1rem",
  background: "none",
  color: "blue",
};

const iconButtonStyle: React.CSSProperties = {
  padding: "10px",
  border: "none",
  cursor: "pointer",
  width: "fit-content",
  background: "none",
};

export const didlabelStyle: React.CSSProperties = {
  color: "rgb(119, 117, 135)",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100px",
};

const inputSearchStyle: React.CSSProperties = {
  padding: "10px",
  borderRadius: "25px",
};

const searchIconStyle: React.CSSProperties = {
  height: "20px",
  width: "20px",
  color: "green",
  cursor: "pointer",
};

export interface ParsedContacts {
  number: string;
  [key: string]: string;
}

const BulkMessagePage = () => {
  const { messageApi } = useMessageToast();
  const dispatch: AppDispatch = useDispatch();
  const {hasPermission} = usePermissions();

  const [csvFile, setCsvFile] = useState<UploadFile[]>([]);
  const [NumbersText, setNumbersText] = useState<string>("");
  const [inputText, setInputText] = useState<string>("");
  const [invalidCsvNumbers, setInvalidCsvNumbers] = useState<string[]>([]);
  const [isTemplatesOpen, setIsTemplatesOpen] = useState<boolean>(false);
  const [selectedDid, setSelectedDid] = useState<string | null>(null);
  const [label, setLabel] = useState<string>("");
  const [selectedView, setSelectedView] = useState<string>("csv");
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isScheduled, setIsScheduled] = useState<boolean>(false);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
  const [invalidContacts, setInvalidContacts] = useState<string[]>([]);
  const [invalidContactsModal, setInvalidContactsModal] = useState<boolean>(false);
  const [validContactsModal, setValidContactsModal] = useState<boolean>(false);
  const [invalidCsvNumbersModal, setInvalidCsvNumbersModal] = useState<boolean>(false);
  const [placeHolders, setPlaceHolders] = useState<string[]>([]);
  const [parsedCsvContacts, setParsedCsvContacts] = useState<{ number: string; [key: string]: string }[]>([]);
  const sentTimeOutRef = useRef<NodeJS.Timeout | null>(null);

  const { selectedAccount, isAdminView } = useSelector((state: RootState) => state.admin);
  const { data: userDids } = useSelector((state: RootState) => state.userDids);
  const { data: templates } = useSelector((state: RootState) => state.templates);
  const { data: userData } = useSelector((state: RootState) => state.user);
  const { data: contacts } = useSelector((state: RootState) => state.contacts);
  const { data: categories } = useSelector((state: RootState) => state.categories);
  const { data: tags } = useSelector((state: RootState) => state.tags);

  const navigate = useNavigate();

  const { mutate: sendBulkMessage, isSuccess: isSuccessBulk, isLoading: bulkMessageLoading, data: bulkData } = useSendBulkMessage();

  const { refetch: fetchTemplates } = useGetTemplates(selectedAccount || null);
  const { refetch } = useGetUserDids(isAdminView ? selectedAccount : null);
  const { refetch: fetchContacts, isLoading: isLoadingContacts } = useGetContacts();
  const { refetch: fetchCategories, isLoading: isLoadingCategories } = useGetCategories();
  const { refetch: fetchTags, isLoading: isLoadingTags } = useGetTagsByCategory();

  useEffect(() => {
    if ((isAdminView && selectedAccount == "") || !isAdminView) {
      dispatch(setSelectedAccount(userData?.accountID as string));
    }
    refetch();
  }, [isAdminView, selectedAccount]);

  useEffect(() => {
    fetchCategories();
    fetchTags();
    if (contacts.length == 0) {
      fetchContacts();
    }
  }, []);



  useEffect(() => {
    fetchTemplates()
  }, [selectedAccount]);

  useEffect(() => {
    if (isSuccessBulk) {
      setCsvFile([]);
      setNumbersText("");
      setInputText("");
      setLabel("");
    }
    return () => {
      if (sentTimeOutRef.current) {
        clearTimeout(sentTimeOutRef.current);
      }
    };
  }, [isSuccessBulk]);

  useEffect(() => {
    const invalidContacts = selectedContacts.filter((contact) => {
      if (!contacts.find((c) => c.id === contact)?.phones.every((phone) => phoneNumberValidate(phone.phone_number))) {
        return contact;
      }
    });
    setInvalidContacts(invalidContacts);
  }, [selectedContacts]);

  const TemplatesComponent = () => {
    return (
      <List<TemplateType>
        bordered
        dataSource={templates}
        style={{
          maxHeight: "180px",
          overflowY: "auto",
        }}
        renderItem={(item) => (
          <List.Item
            onClick={() => {
              setInputText((prev) => `${prev} ${item.body}`);
              setIsTemplatesOpen(false);
            }}
          >
            <List.Item.Meta title={item.name} style={{ cursor: "pointer" }} />
          </List.Item>
        )}
      />
    );
  };

  const handleChangeDid = (did: string) => {
    setSelectedDid(did);
  };

  const props: UploadProps | React.CSSProperties = {
    name: "file",
    multiple: false,
    style: {
      display: "block",
      marginTop: "10px",
    },

    maxCount: 1,
    beforeUpload(file) {
      if (file.type != "text/csv") {
        return messageApi.error("Only CSV files are allowed");
      }
      return false;
    },
    accept: "text/csv",

    onChange(info) {
      const { status } = info.file;
      setCsvFile(info.fileList);
      if (status !== "uploading") {
        if (info.file.type != "text/csv") {
          return messageApi.error("Only CSV files are allowed");
        }
      }
      if (status === "done") {
        setCsvFile(info.fileList);
      } else if (status === "error") {
        console.log("error", info.fileList);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handlePlaceHolders = async () => {
    if (selectedView === "csv" && csvFile.length > 0 && parsedCsvContacts.length > 0) {
      setPlaceHolders(Object.keys(parsedCsvContacts[0]).filter((key) => key !== "number"));
    } else if (selectedView === "contacts" && selectedContacts.length > 0) {
      setPlaceHolders(["first_name", "middle_name", "last_name", "email"]);
    } else {
      setPlaceHolders([]);
    }
  };

  const handleCsv = async () => {
    if (csvFile.length > 0) {
      const base64: string = await getBase64(csvFile[0].originFileObj as RcFile);
      const parsedData: ParsedContacts[] = parseCsv(base64.split(",")[1], true);

      if (!parsedData) {
        return messageApi.error("Invalid CSV file");
      }

      if (parsedData.length === 0) {
        return messageApi.error("CSV file is empty");
      }

      setParsedCsvContacts(parsedData);
    }
  };


  useEffect(() => {
    if (csvFile.length > 0) {
      handleCsv();
    }
  }, [csvFile]);

  useEffect(() => {
    setSearchText("");
  }, [selectedView]);

  useEffect(() => {
    handlePlaceHolders();
  }, [selectedView, csvFile, selectedContacts, parsedCsvContacts]);
  const handleSubmit = async () => {
    try {
      let messagesData: {
        to: string;
        text: string;
      }[] = [];

      if (!selectedDid) {
        return messageApi.error("Please select a number from the list");
      }

      if (!label || label == "") {
        return messageApi.error("Please enter a label for the bulk message");
      }

      if (selectedView === "csv") {
        if (!parsedCsvContacts) {
          return messageApi.error("Invalid CSV file");
        }

        if (parsedCsvContacts.length == 0) {
          return messageApi.error("CSV file is empty");
        }

        const fields = [...placeHolders, "number"];
        const templatePlaceHolders: string[] = findTemplatePlaceHolders(inputText);

        if (!fields.includes("number")) {
          return messageApi.error("Missing required field 'number'");
        }

        const missingFields = templatePlaceHolders.filter((placeholder) => !fields.includes(placeholder));
        if (missingFields.length > 0) {
          throw new Error(`CSV is missing required fields: ${missingFields.join(", ")}`);
        }

        messagesData = (await Promise.all(
          parsedCsvContacts.map(async (data) => {
            if (data.number) {
              const text = await replacePlaceholders(inputText, data);
              return { to: data.number, text };
            }

            return null;
          })
        )) as { to: string; text: string }[];
      } else if (selectedView === "numbers") {
        messagesData = NumbersText.trim().split("\n")
          .map((num) => num.trim())
          .map((num) => {
            return {
              to: num,
              text: inputText,
            };
          });
      } else if (selectedView === "contacts") {
        if (selectedContacts.length == 0) {
          return messageApi.error("Please select contacts");
        }

        const fields = [...placeHolders];
        const templatePlaceHolders: string[] = findTemplatePlaceHolders(inputText);
        const missingFields = templatePlaceHolders.filter((placeholder) => !fields.includes(placeholder));
        if (missingFields.length > 0) {
          throw new Error(`Missing required fields: ${missingFields.join(", ")}`);
        }

        messagesData = (
          await Promise.all(
            selectedContacts.map(async (contact) => {
              const contactData = contacts.find((c) => c.id === contact);

              if (!contactData) {
                return null;
              }

              let data: { [key: string]: any } = {};

              fields.forEach((field) => {
                if (placeHolders.includes(field) && Object.prototype.hasOwnProperty.call(contactData, field)) {
                  data[field] = contactData[field as keyof Contact];
                }
              });

              return {
                to: contactData.phones[0].phone_number,
                text: await replacePlaceholders(inputText, data),
              };
            })
          )
        ).filter((data) => data !== null) as { to: string; text: string }[];
      }

      let invalidNumbers: string[] = [];

      messagesData = messagesData
        .filter((data) => {
          const e164 = phoneNumberFormatE164(data.to);
          if (e164) {
            return true;
          }
          invalidNumbers.push(data.to);
          return false;
        })
        .map((data) => {
          return {
            ...data,
            to: phoneNumberFormatE164(data.to),
          };
        });

      if (invalidNumbers.length > 0) {
        return messageApi.error(`${invalidNumbers.length} numbers ${invalidNumbers.length === 1 ? "is" : "are"} invalid`);
      }

      const bulkMessageData: {
        messages: { to: string; text: string }[];
        from: string;
        label: string;
        scheduled_at?: Date;
      } = {
        messages: messagesData,
        from: selectedDid as string,
        label,
      };

      if (scheduledDate) {
        bulkMessageData.scheduled_at = new Date(scheduledDate);
      }

      sendBulkMessage(bulkMessageData, {
        onSuccess: () => {
          messageApi.success("Bulk message processed successfully");
          navigate("/bulk/list/1");
        },
      });
    } catch (error: any) {
      messageApi.error(error.message);
    }
  };
  return (
    <Flex style={rowStyle}>
      <Flex vertical gap={10} style={{ padding: "10px", height: "100%", overflowY: "auto", width: "100%" }}>
        <IoMdArrowRoundBack onClick={() => navigate("/bulk/list/1")} style={{ color: "#1890ff", fontSize: "20px", cursor: "pointer" }} />
        <Typography.Title level={3}>Bulk SMS</Typography.Title>
        <Typography.Text type="secondary">
          Send SMS messages in bulk using three methods: CSV Upload, Select from Contacts, or Manual. Choose a sender number, write or use message templates, validate phone numbers, schedule messages,
          and track status post-submission. The user-friendly interface ensures seamless bulk SMS management.
        </Typography.Text>
        <Flex gap={10} align="center">
          <Select
            placeholder="Select Number"
            style={{
              height: "fit-content",
              width: "200px",
              background: "rgb(244, 240, 234)",
            }}
            optionFilterProp="children"
            value={selectedDid}
            onChange={handleChangeDid}
            options={userDids
              .filter((userDid) => userDid.allow_bulk)
              .map((userDid) => {
                return {
                  label: (
                    <Flex vertical>
                      <Tooltip title={userDid.label ? userDid.label : ""}>
                        <Typography.Text>{formatPhoneNumber(userDid.did)}</Typography.Text>
                      </Tooltip>
                    </Flex>
                  ),
                  value: userDid.did,
                };
              })}
          />
          <Input placeholder="Label" value={label} onChange={(e) => setLabel(e.target.value)} style={{ display: "block", width: "9%" }}></Input>
        </Flex>
        <Segmented
          style={{
            width: "fit-content",
            background: "white",
            borderRadius: "10px",
          }}
          color="#1890ff"
          value={selectedView}
          onChange={(value) => setSelectedView(value)}
          options={[
            {
              label: "CSV Upload",
              value: "csv",
              icon: <FaFileCsv />,
            },
            {
              label: "Select from Contacts",
              value: "contacts",
              icon: <RiContactsBook2Line />,
            },
            {
              label: "Manual",
              value: "numbers",
              icon: <FaRegCopy />,
            },
          ]}
        />

        <Flex justify="space-around" flex={"auto"} gap={10}>
          <Flex style={{ width: "48%" }} vertical gap={10}>
            {selectedView == "csv" && (
              <Flex vertical gap={10}>
                {[...parsedCsvContacts].map((data) => (phoneNumberValidate(data["number"]) ? null : data["number"])).filter((el) => el !== null).length > 0 && invalidCsvNumbersModal && (
                  <ModalWrapper title="Invalid Numbers" setIsModalOpen={setInvalidCsvNumbersModal} isModalOpen={invalidCsvNumbersModal}>
                    <InvalidCsvList contacts={parsedCsvContacts.filter((data) => !phoneNumberValidate(data["number"]))} setParsedContacts={setParsedCsvContacts} setIsModal={setInvalidCsvNumbersModal} type="invalid" />
                  </ModalWrapper>
                )}
                <Flex gap={10} align="center">
                  <Typography.Text type="secondary"> Drag and drop CSV file here</Typography.Text>
                  {[...parsedCsvContacts].map((data) => (phoneNumberValidate(data["number"]) ? null : data["number"])).filter((el) => el !== null).length > 0 && (
                    <Tag onClick={() => setInvalidCsvNumbersModal(true)} style={{ cursor: "pointer" }} color="red">
                      Invalid Numbers {[...parsedCsvContacts].map((data) => (phoneNumberValidate(data["number"]) ? null : data["number"])).filter((el) => el !== null).length}
                    </Tag>
                  )}
                </Flex>
                <Dragger
                  fileList={csvFile}
                  previewFile={getBase64 as any}
                  height={260}
                  onRemove={() => {
                    setParsedCsvContacts([]);
                    setCsvFile([]);
                    setInvalidCsvNumbersModal(false);
                  }}
                  style={{ width: "40%", marginTop: "10px", display: "block", height: "260px", background: "#e7edf3", backgroundColor: "rgb(231, 237, 243)" }}
                  {...props}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">Support for a single CSV file upload.</p>
                </Dragger>
              </Flex>
            )}
            {selectedView == "contacts" && (
              <Flex gap={10} vertical>
                {invalidContacts.length > 0 && invalidContactsModal && (
                  <ModalWrapper isModalOpen={invalidContactsModal} setIsModalOpen={setInvalidContactsModal} title="Invalid Contacts">
                    <SelectedContacts setIsModal={setInvalidContactsModal} type="invalid" contacts={invalidContacts} setSelectedContacts={setSelectedContacts} />
                  </ModalWrapper>
                )}
                {validContactsModal && selectedContacts.filter((contact) => !invalidContacts.includes(contact)).length > 0 && (
                  <ModalWrapper isModalOpen={validContactsModal} setIsModalOpen={setValidContactsModal} title="Valid Contacts">
                    <SelectedContacts
                      setIsModal={setValidContactsModal}
                      type="valid"
                      contacts={selectedContacts.filter((contact) => !invalidContacts.includes(contact))}
                      setSelectedContacts={setSelectedContacts}
                    />
                  </ModalWrapper>
                )}
                <Input
                  style={{
                    ...inputSearchStyle,
                  }}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  size="middle"
                  placeholder="Search"
                  prefix={<IoSearch style={searchIconStyle} />}
                />
                <Flex gap={10} align="center">
                  <TreeSelect
                    showSearch
                    style={{ width: "50%", borderRadius: "25px" }}
                    placeholder="Filter by tags"
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    allowClear
                    treeCheckable
                    loading={isLoadingCategories || isLoadingTags}
                    onChange={(value) => {
                      setSelectedTags(value);
                    }}
                    value={selectedTags}
                    treeData={[
                      ...categories,
                      {
                        name: "Uncategorized",
                        id: "uncategorized",
                      },
                    ].map((category) => {
                      return {
                        title: category.name,
                        value: category.name,
                        key: category.name,
                        children: tags
                          .filter((tag) => {
                            if (category.name === "Uncategorized") {
                              return tag.category === null;
                            } else if (category.name !== null) {
                              return tag.category === category.name;
                            }
                          })
                          .map((tag) => {
                            return {
                              title: tag.name,
                              value: tag.name,
                              key: tag.name,
                            };
                          }),
                      };
                    })}
                  />
                  {selectedContacts.filter((contact) => !invalidContacts.includes(contact)).length > 0 && (
                    <Tag onClick={() => setValidContactsModal(true)} style={{ cursor: "pointer" }} color="green">
                      Valid Contacts: {selectedContacts.filter((contact) => !invalidContacts.includes(contact)).length}
                    </Tag>
                  )}
                  {invalidContacts.length > 0 && (
                    <Tag onClick={() => setInvalidContactsModal(true)} style={{ cursor: "pointer" }} color="red">
                      Invalid Contacts: {invalidContacts.length}
                    </Tag>
                  )}
                </Flex>
                <ContactsTable
                  contacts={contacts}
                  setSelectedContacts={setSelectedContacts}
                  selectedContacts={selectedContacts}
                  searchText={searchText}
                  selectedTags={selectedTags}
                  isLoadingContacts={isLoadingContacts}
                />
              </Flex>
            )}
            {selectedView == "numbers" && (
              <Flex vertical gap={10}>
                <Typography.Text type="secondary" style={{ display: "block", marginTop: "10px" }}>
                  Enter phone numbers manually, one per line. e.g., 5551234567
                </Typography.Text>

                <TextArea
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^(\+|\d|\s|\(|\)|-|\.|\n)*$/;

                    if (value === "" || regex.test(value)) {
                      setNumbersText(value);
                    }
                  }}
                  className="manual-numbers-input"
                  style={{ width: "100%", background: "#e7edf3", display: "block", height: "240px", marginBottom: "10px" }}
                  value={NumbersText}
                  placeholder="5551234567&#10;5551234568"
                  allowClear
                />
              </Flex>
            )}
          </Flex>

          <Flex gap={8} vertical style={{ width: "48%" }}>
            <Typography.Text type="secondary" style={{ display: "block", marginTop: "10px" }}>{`Enter your message add dynamic fields e.g. {{first_name}}`}</Typography.Text>

            <Flex style={{ background: "white", borderRadius: "10px", padding: "2px" }} gap={10} vertical>
              <Mentions
                variant="borderless"
                onChange={(e) => {
                  setInputText(e);
                }}
                prefix={"{{"}
                value={inputText}
                style={{ background: "white", minHeight: "120px", cursor: "text" }}
                placeholder="Your message here {{first_name}} {{last_name}} {{email}}"
                allowClear
                aria-setsize={10}
                autoSize
                rows={4}
                options={placeHolders.map((placeholder) => {
                  return {
                    label: placeholder,
                    value: `${placeholder}}}`,
                  };
                })}
              />

              {(selectedView == "csv" || selectedView == "contacts") && (
                <Flex gap={10} align="center" style={{ padding: "10px" }}>
                  {placeHolders.map((placeholder) => (
                    <Tag style={{ cursor: "pointer" }} onClick={() => setInputText(inputText + `{{${placeholder}}}`)} color="green">
                      {placeholder}
                    </Tag>
                  ))}
                </Flex>
              )}
              <Popover
                overlayStyle={{ width: "20%" }}
                content={TemplatesComponent}
                onOpenChange={setIsTemplatesOpen}
                title={"Templates"}
                trigger="click"
                open={isTemplatesOpen}
                className="templates-popover"
                style={{ width: "35%" }}
                placement="topLeft"
              >
                <Button style={iconButtonStyle}>
                  <RiMenu2Fill style={iconStyle} />
                </Button>
              </Popover>
            </Flex>
            <Flex gap={10} align="center">
              <Switch style={{ width: "fit-content" }} onChange={setIsScheduled} checkedChildren={"Schedule"} unCheckedChildren={"Send Now"} />
              {isScheduled && (
                <DatePicker
                  showTime
                  format="MM/DD/YYYY hh:mm A"
                  placeholder="Select Date and Time"
                  onChange={(date) => (date ? setScheduledDate(new Date(dayjs(date).format("YYYY-MM-DD HH:mm"))) : setScheduledDate(null))}
                  value={scheduledDate ? dayjs(scheduledDate) : null}
                  disabledDate={(current) => current < dayjs().startOf("day")}
                  disabledTime={disabledTime}
                />
              )}
            </Flex>
            <Button
              loading={bulkMessageLoading}
              disabled={(NumbersText.length == 0 && csvFile.length == 0 && selectedContacts.length == 0) || inputText.length == 0}
              onClick={handleSubmit}
              block
              type="primary"
              style={{ width: "40%", display: "block" }}
            >
              Send SMS
            </Button>
            {selectedDid && (
              <Typography.Text strong type="secondary" style={{ display: "block", marginTop: "10px" }}>
                Send message from <Typography.Text strong> {formatPhoneNumber(selectedDid as string)}</Typography.Text>
              </Typography.Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BulkMessagePage;
