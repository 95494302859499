import { Button, Card, Col, DatePicker, Dropdown, DropdownProps, Flex, Input, Menu, Radio, Row, Select, Space, TimePicker, Typography } from "antd";
import { SmsReportQuery, SmsStatistics, useGetSmsReport } from "../../hooks/apis/reports.api";
import { StaticCard } from "../../components/Reports/StatisticCard";
import { IoFilter, IoSearch } from "react-icons/io5";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { formatPhoneNumber } from "../../utils/phoneLib";
import dayjs from "dayjs";
import MessagesTable from "../../components/Reports/Table/Messages";
import { TbMessageCircle, TbMessageCircleDown, TbMessageCircleUp, TbMessageCircleX } from "react-icons/tb";
import { useGetUsersByDids } from "../../hooks/apis/user.api";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { debounce } from "../../utils/debounce";

const { RangePicker } = DatePicker;

const reportsContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
};

const renderReports = (statistics: SmsStatistics) => {
  const statisticFields = [
    {
      title: "Total",
      icon: <TbMessageCircle color="white" size={30} />,
      className: "bnb2",
      value: statistics.total,
    },
    {
      title: "Received",
      icon: <TbMessageCircleDown size={30} />,
      className: "bnb2",
      value: statistics.received,
    },
    {
      title: "Sent",
      icon: <TbMessageCircleUp size={30} />,
      className: "bnb2",
      value: statistics.delivered,
    },
    {
      title: "Failed",
      icon: <TbMessageCircleX size={30} />,
      className: "redtext",
      value: statistics.failed,
    },
  ];
  return (
    <Row className="rowgap-vbox" gutter={[24, 0]} style={{ width: "100%", margin: "0px" }}>
      {statisticFields.map((field) => {
        return (
          <Col key={field.title} xs={24} sm={24} md={12} lg={6} xl={6} className="mb-24">
            <StaticCard title={field.title} value={field.value} className={field.className} icon={field.icon} />
          </Col>
        );
      })}
    </Row>
  );
};

const ReportsPage = () => {
  const navigate: NavigateFunction = useNavigate();
  const pageSize = 20;
  const { page } = useParams();
  const {data: user} = useSelector((state: RootState) => state.user);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState<SmsReportQuery>({
    offset: 0,
    start_date: new Date(dayjs().startOf("day").toISOString()),
    end_date: new Date(dayjs().endOf("day").toISOString()),
    start_time: null,
    end_time: null,
    direction: null,
    user: null,
    account_id: user?.accountID as string,
    dids: null,
    searchInput: null,
  });
  const [selectedDateOption, setSelectedDateOption] = useState<string | null>("Today");
  const [customDateRange, setCustomDateRange] = useState<any[]>([]);
  const [openDateOptionModal, setOpenDateOptionModal] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<SmsStatistics>({
    total: 0,
    received: 0,
    delivered: 0,
    failed: 0,
    pending: 0,
    unread: 0,
    read: 0,
    scheduled: 0,
  });

  const { data: dids } = useSelector((state: RootState) => state.userDids);
  const { data: users } = useSelector((state: RootState) => state.didUsers);
  const { refetch: fetchAllUsers } = useGetUsersByDids(dids.map((did) => did.did));
  const {
    data: reports,
    refetch: refetchReports,
    isLoading: isLoadingReports,
  } = useGetSmsReport({
    ...filters,
    start_date: filters.start_date,
    end_date: filters.end_date,
    offset: page ? (parseInt(page) - 1) * pageSize : 0,
    limit: 20,
  });

  useEffect(()=>{
    if(reports && reports.statistics){
      setStatistics(reports.statistics);
    }
  },[reports])

  const handleSearchChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    handleRefetchReports();
  }, 800);

  useEffect(() => {
    refetchReports();
  }, [page]);

  useEffect(() => {
    if (dids.length) {
      fetchAllUsers();
    }
  }, [dids]);

  const dateOptions = [
    {
      key: "Today",
      label: "Today",
      startTime: dayjs().startOf("day").toISOString(),
      endTime: dayjs().endOf("day").toISOString(),
    },
    {
      key: "Yesterday",
      label: "Yesterday",
      startTime: dayjs().subtract(1, "day").startOf("day").toISOString(),
      endTime: dayjs().subtract(1, "day").endOf("day").toISOString(),
    },
    {
      key: "Week to Date",
      label: "Week to Date",
      startTime: dayjs().startOf("week").toISOString(),
      endTime: dayjs().endOf("day").toISOString(),
    },
    {
      key: "Last Week",
      label: "Last Week",
      startTime: dayjs().subtract(1, "week").startOf("week").toISOString(),
      endTime: dayjs().subtract(1, "week").endOf("day").toISOString(),
    },
    {
      key: "Last 7 Days",
      label: "Last 7 Days",
      startTime: dayjs().subtract(7, "day").startOf("day").toISOString(),
      endTime: dayjs().subtract(1, "day").endOf("day").toISOString(),
    },
    {
      key: "Month to Date",
      label: "Month to Date",
      startTime: dayjs().startOf("month").toISOString(),
      endTime: dayjs().endOf("day").toISOString(),
    },
    {
      key: "Last Month",
      label: "Last Month",
      startTime: dayjs().subtract(1, "month").startOf("month").toISOString(),
      endTime: dayjs().subtract(1, "month").endOf("month").toISOString(),
    },
    {
      key: "Last 30 Days",
      label: "Last 30 Days",
      startTime: dayjs().subtract(30, "day").startOf("day").toISOString(),
      endTime: dayjs().subtract(1, "day").endOf("day").toISOString(),
    },
    {
      key: "Last 90 Days",
      label: "Last 90 Days",
      startTime: dayjs().subtract(90, "day").startOf("day").toISOString(),
      endTime: dayjs().subtract(1, "day").endOf("day").toISOString(),
    },
    {
      key: "Year to Date",
      label: "Year to Date",
      startTime: dayjs().startOf("year").toISOString(),
      endTime: dayjs().endOf("day").toISOString(),
    },
    {
      key: "Last Year",
      label: "Last Year",
      startTime: dayjs().subtract(1, "year").startOf("year").toISOString(),
      endTime: dayjs().subtract(1, "year").endOf("year").toISOString(),
    },
  ];


  const handleRefetchReports = () => {
    if (Number(page) > 1) {
      navigate("/reports/list/1");
    } else {
      setTimeout(() => {
        refetchReports();
      }, 200);
    }
  };

  const handleOpenChange: DropdownProps["onOpenChange"] = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpenDateOptionModal(nextOpen);
    }
  };

  const menuItems = dateOptions.map((option) => ({
    key: option.key,
    label: option.label,
  }));

  const menu = (
    <Menu
      items={[
        ...menuItems.map((item) => ({
          key: item.key,
          label: (
            <Radio.Group style={{ width: "100%" }} value={selectedDateOption} onChange={(e) => setSelectedDateOption(e.target.value)}>
              <Radio
                style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", width: "100%" }}
                onChange={(e) => {
                  setSelectedDateOption(e.target.value);
                  setCustomDateRange([]);
                  const selectedOption = dateOptions.find((option) => option.key === e.target.value);
                  if (selectedOption) {
                    setFilters({
                      ...filters,
                      start_date: new Date(selectedOption.startTime),
                      end_date: new Date(selectedOption.endTime),
                    });
                  }
                  handleRefetchReports();
                }}
                value={item.key}
              >
                <Typography.Text strong style={{ width: "100%" }}>
                  {item.label}
                </Typography.Text>
              </Radio>
            </Radio.Group>
          ),
        })),
        {
          key: "custom",
          label: (
            <RangePicker
              style={{ background: "#EFEDFF" }}
              format={"MM/DD/YYYY"}
              disabledDate={(current) => current && current > dayjs().endOf("day")}
              placeholder={["Start Date", "End Date"]}
              allowClear
              defaultValue={undefined}
              onChange={(value) => {
                if (value && value[0] && value[1]) {
                  setCustomDateRange([value[0].toISOString(), value[1].toISOString()]);
                  setSelectedDateOption(null);
                  setFilters({
                    ...filters,
                    start_date: new Date(value[0].toISOString()),
                    end_date: new Date(value[1].toISOString()),
                  });
                  handleRefetchReports();
                } else {
                  setCustomDateRange([]);
                }
              }}
              value={customDateRange && customDateRange.length > 0 ? [dayjs(customDateRange[0]), dayjs(customDateRange[1])] : undefined}
            />
          ),
        },
        {
          key: "clear",
          label: (
            <Button
              style={{ width: "fit-content" }}
              type="link"
              onClick={() => {
                setSelectedDateOption("Today");
                setCustomDateRange([]);
                setFilters({
                  ...filters,
                  start_date: new Date(dayjs().subtract(1, "day").startOf("day").toISOString()),
                  end_date: new Date(dayjs().subtract(1, "day").endOf("day").toISOString()),
                });
                handleRefetchReports();
              }}
            >
              Clear
            </Button>
          ),
        },
      ]}
    />
  );

  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      <Flex vertical gap={10} style={{ padding: "20px" }}>
        <Flex gap={10} vertical style={reportsContainerStyle}>
          {renderReports(statistics)}
        </Flex>
        <Card bordered={false} className="criclebox cardbody h-full">
          <Flex align="center" justify="space-between">
            <div className="project-ant">
              <Flex gap={10}>
                <Dropdown className="date-dropdown-container" open={openDateOptionModal} overlay={menu} trigger={["click"]} onOpenChange={handleOpenChange}>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenDateOptionModal(true);
                    }}
                  >
                    <Space>
                      <FaCalendarAlt style={{ marginTop: "4px" }} />
                      {customDateRange.length > 1 ? (
                        <Typography.Text style={{ color: "white" }} strong>
                          {dayjs(filters.start_date).format("MM/DD/YYYY")} - {dayjs(filters.end_date).format("MM/DD/YYYY")}
                        </Typography.Text>
                      ) : selectedDateOption ? (
                        <Typography.Text style={{ color: "white" }} strong>
                          {selectedDateOption}
                        </Typography.Text>
                      ) : (
                        <Typography.Text style={{ color: "white" }} strong>
                          Today
                        </Typography.Text>
                      )}
                      <FaCaretDown style={{ marginTop: "4px" }} />
                    </Space>
                  </Button>
                </Dropdown>
                <Input
                  className="report-select-filter"
                  style={{ minWidth: "400px", maxWidth: "400px", borderRadius: "15px" }}
                  placeholder="Search by message body"
                  prefix={<IoSearch />}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      searchInput: e.target.value.trim(),
                    });
                    handleSearchChange(e);
                  }}
                  allowClear
                />
              </Flex>
            </div>
            <Flex gap={10} style={{ marginRight: "10px" }}>
              <Button
                style={{ width: "fit-content" }}
                onClick={() => {
                  setIsFilterOpen(!isFilterOpen);
                }}
                icon={<IoFilter />}
                type="default"
              >
                {"Filters"}
              </Button>
              {isFilterOpen ? (
                <Button
                  onClick={() => {
                    setFilters({
                      ...filters,
                      searchInput: "",
                      dids: [],
                      user: null,
                      direction: null,
                      start_time: null,
                      end_time: null,
                      start_date: new Date(dayjs().subtract(1, "day").startOf("day").toISOString()),
                      end_date: new Date(dayjs().subtract(1, "day").endOf("day").toISOString()),
                    });
                    setCustomDateRange([]);
                    setSelectedDateOption("Today");
                    handleRefetchReports();
                  }}
                  type="default"
                >
                  Clear
                </Button>
              ) : null}
            </Flex>
          </Flex>
          {isFilterOpen && (
            <Flex gap={10} align="center">
              <Select
                className="report-select-filter"
                options={[
                  ...dids.map((did) => {
                    return {
                      label: formatPhoneNumber(did.did),
                      value: did.did,
                    };
                  }),
                ]}
                onClear={() => {
                  setFilters({
                    ...filters,
                    dids: [],
                  });
                }}
                allowClear
                mode="multiple"
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    dids: value,
                  });
                }}
                value={filters.dids}
                style={{ minWidth: "200px", maxWidth: "200px", margin: "10px" }}
                placeholder="Number"
              />
              <Select
                className="report-select-filter"
                placeholder="User"
                style={{ minWidth: "200px", maxWidth: "200px", margin: "10px" }}
                options={[
                  ...users.map((user) => {
                    return {
                      label: `${user.first_name} ${user.last_name}`,
                      value: user.id,
                    };
                  }),
                ]}
                onClear={() => {
                  setFilters({
                    ...filters,
                    user: "",
                  });
                }}
                allowClear
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    user: value,
                  });
                }}
                value={filters.user}
              />
              <Select
                className="report-select-filter"
                placeholder="Direction"
                style={{ minWidth: "200px", maxWidth: "200px", margin: "10px" }}
                onClear={() => {
                  setFilters({
                    ...filters,
                    direction: "",
                  });
                }}
                value={filters.direction}
                allowClear
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    direction: value,
                  });
                }}
                options={[
                  {
                    label: "Incoming",
                    value: "inbound",
                  },
                  {
                    label: "Outgoing",
                    value: "outbound",
                  },
                ]}
              />
              <TimePicker.RangePicker
                style={{ background: "#EFEDFF" }}
                format={"hh:mm A"}
                value={filters.start_time && filters.end_time ? [dayjs(filters.start_time), dayjs(filters.end_time)] : null}
                onChange={(val) => {
                  if (val && val[0] && val[1]) {
                    const startDateTime = dayjs(filters.start_date).hour(val[0].hour()).minute(val[0].minute()).second(0);
                    const endDateTime = dayjs(filters.end_date).hour(val[1].hour()).minute(val[1].minute()).second(0);

                    setFilters({
                      ...filters,
                      start_time: startDateTime.toDate(),
                      end_time: endDateTime.toDate(),
                    });
                  } else {
                    setFilters({
                      ...filters,
                      start_time: null,
                      end_time: null,
                    });
                  }
                }}
                placeholder={["Start Time", "End Time"]}
                allowClear
              />
              <Button
                loading={isLoadingReports}
                onClick={() => {
                  refetchReports();
                  navigate("/reports/list/1");
                }}
                type="primary"
              >
                Apply
              </Button>
            </Flex>
          )}
          <MessagesTable
            isLoading={isLoadingReports}
            statistics={statistics}
            messages={reports?.messages || []}
            page={page ? parseInt(page) : 1}
          />
        </Card>
      </Flex>
    </div>
  );
};

export default ReportsPage;
