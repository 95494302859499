import { api } from "@data-phone/react-resource-server-auth";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { setTagsSlice, Tag } from "../../store/slices/tags.slice";
import { AppDispatch } from "../../store/store";

const getTagsByCategoryApi = async () => {
  const response = await api.get(`
        ${process.env.REACT_APP_CONTACT_API_URL}/tags
        `);

  return response.data;
};

export const useGetTagsByCategory = () => {
  const dispatch: AppDispatch = useDispatch();

  return useQuery<Tag[], Error>("tags", () => getTagsByCategoryApi(), {
    enabled: false,
    onSuccess(data) {
      dispatch(setTagsSlice(data));
    },
  });
};
