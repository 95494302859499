import { useEffect } from "react";

const UseScrollObserver = ({ setVisibleMessage, cardRefs, cards }: { setVisibleMessage: React.Dispatch<React.SetStateAction<number | null>>; cardRefs: any; cards: any[] }) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.getAttribute("data-index") ) {
              setVisibleMessage(Number(entry.target.getAttribute("data-index")));
            }
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    if (cardRefs.current) {
      cardRefs.current.forEach((ref: any) => {
        if (ref) {
          observer.observe(ref);
        }
      });
    }

    return () => {
      if (cardRefs.current) {
        cardRefs.current.forEach((ref: any) => {
          if (ref) {
            observer.unobserve(ref);
          }
        });
      }
    };
  }, [cards]);
};

export default UseScrollObserver;
