import { Button, Flex,  Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddRingotelUserModal from "../../components/Modal/AddRingotelUser";
import { RootState } from "../../store/store";
import RingotelUsersTable from "../../components/Admin/Tables/RingotelUsers";
import { useGetRingotelOrganizations } from "../../hooks/apis/ringotel.api";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useGetAllUsers } from "../../hooks/apis/user.api";
import { IoMdArrowRoundBack } from "react-icons/io";
const RingotelUsers = () => {
  const [account_id, setAccountId] = useState<string | null>(null);
  const [addRingotelUserModal, setAddRingotelUserModal] = useState(false);

  const { data: ringotelOrgs } = useSelector((state: RootState) => state.ringotelOrgs);
  const { accounts, users } = useSelector((state: RootState) => state.admin);

  const { refetch: refetchRingotelOrganization } = useGetRingotelOrganizations();
  const { refetch: fetchAllUsers } = useGetAllUsers();

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    refetchRingotelOrganization();
  }, []);

  useEffect(() => {
    setAccountId(ringotelOrgs[0]?.account_id ? ringotelOrgs[0]?.account_id : "");
  }, [ringotelOrgs]);

  useEffect(() => {
    if (users.length == 0) {
      fetchAllUsers();
    }
  }, []);

  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Flex vertical gap={10} style={{ padding: "20px" }}>
      {addRingotelUserModal && account_id && <AddRingotelUserModal account_id={account_id} setIsModalOpen={setAddRingotelUserModal} isModalOpen={addRingotelUserModal} />}
      <IoMdArrowRoundBack onClick={() => navigate("/integrations")} style={{ color: "#1890ff", fontSize: "20px", cursor: "pointer" }} />
      <Flex justify="space-between" align="center" style={{ padding: "10px" }}>
        <Typography.Title>{`Ringotel Users`}</Typography.Title>{" "}
        <Flex gap={10}>
          {account_id && ringotelOrgs.length > 0 && (
            <Button
              type="primary"
              onClick={() => {
                setAddRingotelUserModal(true);
              }}
            >
              Add Ringotel User
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              navigate("/ringotel-orgs");
            }}
          >
            Ringotel Organizations
          </Button>
        </Flex>
      </Flex>
      <Select
        style={{ minWidth: "200px",maxWidth:"fit-content" }}
        showSearch
        placeholder="Select Organization"
        optionFilterProp="children"
        value={account_id || null}
        filterOption={filterOption}
        onChange={(value) => {
          setAccountId(value);
        }}
        options={ringotelOrgs.map((ringotelOrg) => {
          return {
            label: accounts.find((account) => account.id === ringotelOrg.account_id)?.name || "",
            value: ringotelOrg.account_id,
          };
        })}
      />
      <div style={{ marginTop: "20px" }}>{account_id && account_id !== "" && <RingotelUsersTable account_id={account_id} />}</div>
    </Flex>
  );
};
export default RingotelUsers;
