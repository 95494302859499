import { Button, Card, Divider, Flex, Typography } from "antd";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import { SettingOutlined } from "@ant-design/icons";
import RingotelLogo from "../../assets/images/logo/ringotel.svg";

const IntegrationsPage = () => {
  const navigate = useNavigate();

  return (
    <div style={{ padding: "20px", width: "100%" }}>
      <Flex align="center" justify="space-between">
        <Typography.Title>Integrations</Typography.Title>
      </Flex>

      <Flex>
        <div style={{ width: 300, borderRadius: "10px", padding: "5px" ,background:"#fff"}}>
          <img alt="Ringotel" src={RingotelLogo} style={{ width: "260px", margin: "25px" }}></img>
          <Button style={{ width: "100%" }} type="primary" icon={<SettingOutlined />} onClick={() => navigate("/ringotel-users")}></Button>
        </div>
      </Flex>
    </div>
  );
};

export default IntegrationsPage;
