import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TagCategory {
  name: string;
  account_id: string;
  description: string;
}

export interface TagCategoriesState {
  data: TagCategory[];
}

const initialState: TagCategoriesState = {
  data: [],
};

export const tagCategoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setTagCategoriesSlice: (state, action: PayloadAction<TagCategory[]>) => {
      state.data = action.payload;
    },
  },
});

export const { setTagCategoriesSlice } = tagCategoriesSlice.actions;

export default tagCategoriesSlice.reducer;
