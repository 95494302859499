import { Avatar, Badge, Dropdown, Flex, List, MenuProps, Tooltip } from "antd";
import { Conversation } from "../../../interfaces/conversations.type";
import { DateTextWrapper, HeadingText } from "../../generic/Typography";
import { formatPhoneNumber } from "../../../utils/phoneLib";
import { UserAddOutlined } from "@ant-design/icons";
import { HiDotsVertical } from "react-icons/hi";
import { Content } from "antd/es/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { setSelectedConversation } from "../../../store/slices/conversation.slice";
import { generateNameSpaceColor } from "../../../utils/color";
import { Typography } from "antd";
import assign from "../../../assets/images/icon/Assign.svg";
import resolveImg from "../../../assets/images/icon/Check-circle.svg";
import { useState } from "react";
import ModalWrapper from "../../generic/modal";
import { UsersList } from "../didUsers/user.list";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";
import { FiUserMinus } from "react-icons/fi";
import { formatMessageDate } from "../../../utils/messageDateFormat";




const { Text } = Typography;
const boxStyle: React.CSSProperties = {
  padding: "5px",
  border: "none",
  position: "relative",
  cursor: "pointer",
};

const ItemContentStyle: React.CSSProperties = {
  width: "100%",
  padding: "5px",
  borderRadius: "10px",
};
const avatarStyle: React.CSSProperties = {
  verticalAlign: "middle",
  color: "black",
  fontWeight: "bold",
};

const ConversationContentStyle: React.CSSProperties = { width: "50%" };
const assignedUserStyle: React.CSSProperties = {
  color: "blue",
  height: "20px",
  width: "20px",
};
const dotsWrapperStyle: React.CSSProperties = {
  height: "100%",
  top: "0px",
  right: "5px",
  position: "absolute",
};
const dotsStyle: React.CSSProperties = { color: "gray" };

const conversationDateStyle: React.CSSProperties = {
  fontWeight: "500",
  lineHeight: "20px",
  color: "rgb(119, 117, 135)",
  fontSize: "14px",
  minWidth: "85px",
  textAlign: "center"
}

const conversationMessageStyle: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  color: "rgb(119, 117, 135)",
};

const ConversationsCard = ({
  conversation,
  index,
  isSelected,
  setIsNewConversation,
  assigned,
  updateConversation,
}: {
  conversation: Conversation;
  index: number;
  isSelected: boolean;
  setIsNewConversation: Function;
  assigned: string | null;
  updateConversation: Function;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { data: didUsers } = useSelector((state: RootState) => state.didUsers);
  const [isAssignConversation, setIsAssignConversation] = useState(false);
  const { data: contacts } = useSelector((state: RootState) => state.contacts);

  const onClickHandler = (conversation: Conversation) => {
    dispatch(
      setSelectedConversation({
        did: conversation.did,
        participant: conversation.participant,
      })
    );
    setIsNewConversation(false);
  };
  const color = generateNameSpaceColor(conversation.participant);
  const contactData = contacts.find((contact) => contact.phones.some((phone) => {
    return phone.phone_number === conversation.participant
  })) || null

  const conversationMenu: MenuProps["items"] = [
    {
      key: "1",
      label: "Assign",
      icon: (
        <img
          src={assign}
          alt="assign"
          style={{ height: "20px", width: "20px" }}
        />
      ),
      onClick: () => {
        setIsAssignConversation(true);
      },
    },
    {
      key: "2",
      label: conversation.resolved ? "Unresolve" : "Resolve",
      icon: (
        conversation.resolved ?
          <RxCrossCircled style={{ height: "20px", width: "20px" }} /> :
          (<img
            src={resolveImg}
            alt="resolve"
            style={{ height: "20px", width: "20px", opacity: conversation.resolved ? "0.2" : "1" }}
          />)
      ),
      onClick: () => {
        updateConversation({ did: conversation.did, participant: conversation.participant, resolved_at: conversation.resolved ? null : new Date() });
      },
    },
    {
      key: "3",
      label: "Unassign",
      disabled: conversation.assigned_user === null,
      icon: (
        <FiUserMinus style={{ height: "20px", width: "20px" }} fontWeight="700" />
      ),
      onClick: () => {
        updateConversation({ did: conversation.did, participant: conversation.participant, assigned_user: null });
      },
    }
  ];

  return (
    <Content key={index}>
      {isAssignConversation && (
        <ModalWrapper
          title={"Assign"}
          setIsModalOpen={setIsAssignConversation}
          isModalOpen={isAssignConversation}
        >
          <UsersList
            updateConversation={updateConversation}
            setIsModalOpen={setIsAssignConversation}
            isModalOpen={isAssignConversation}
            conversation={{
              did: conversation.did,
              participant: conversation.participant,
            }}
          />
        </ModalWrapper>
      )}
      <List.Item style={boxStyle}>
        <Flex style={{ width: "100%" }}>
          <Flex
            onClick={() => onClickHandler(conversation)}
            align="flex-start"
            justify="space-evenly"
            style={{
              ...ItemContentStyle,
              background: isSelected ? "#eff4fb" : "#ffff",
            }}
            wrap="wrap"
            gap="small"
          >
            <Avatar style={{ ...avatarStyle, background: color }} size="large">

              {conversation.participant[conversation.participant.length - 1]
                ? conversation.participant[conversation.participant.length - 1]
                : 0}
            </Avatar>
            <Flex gap={"small"} style={ConversationContentStyle} vertical={true}>
              <Flex gap={8}>
                <HeadingText
                  text={contactData ? `${contactData.first_name || ""} ${contactData.middle_name || ""} ${contactData.last_name || ""} ` : formatPhoneNumber(conversation.participant) as string}
                />
                {assigned && (
                  <Tooltip
                    title={`Assigned to ${didUsers.find((didUser) => didUser.id === assigned)
                      ?.first_name
                      } ${didUsers.find((didUser) => didUser.id === assigned)
                        ?.last_name
                      }`}
                    color={"#108ee9"}
                  >
                    <UserAddOutlined style={assignedUserStyle} />
                  </Tooltip>
                )}
                {conversation.resolved && (
                  <Tooltip title="Resolved" color={"#108ee9"}>
                    <IoIosCheckmarkCircle
                      style={assignedUserStyle}
                    />
                  </Tooltip>
                )}
              </Flex>
              <Text style={conversationMessageStyle}>
                {conversation.last_message}
              </Text>
            </Flex>
            <Flex align="center" vertical={true}>
              <Typography.Text style={conversationDateStyle}>{formatMessageDate(new Date(conversation.last_message_time))}</Typography.Text>
              {conversation.total_unread
                ? conversation.total_unread > 0 && (
                  <Badge
                    count={
                      `${conversation.total_unread}`.padStart(2, "0")
                    }
                    showZero={false}
                    color="#653BFA"
                  />
                )
                : conversation.unread && conversation.total_unread === 0 && (
                  <Badge
                    text=" "
                    showZero={false}
                    color="#653BFA"
                  />
                )}
            </Flex>
          </Flex>
          <Flex style={dotsWrapperStyle} justify="center" align="center">
            <Dropdown menu={{ items: conversationMenu }} trigger={["click"]}>
              <HiDotsVertical style={dotsStyle} />
            </Dropdown>
          </Flex>
        </Flex>
      </List.Item>
    </Content>
  );
};

export default ConversationsCard;
