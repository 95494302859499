import { Button, Checkbox, Dropdown, DropdownProps, Flex, Input, MenuProps, Tag } from "antd";
import { IoSearch } from "react-icons/io5";
import { LuSettings2 } from "react-icons/lu";
import { FaPlus } from "react-icons/fa";
import { AppDispatch } from "../../../store/store";
import { useDispatch } from "react-redux";
import { setSelectedConversation } from "../../../store/slices/conversation.slice";
import { ChangeEvent, useState } from "react";
import { debounce } from "../../../utils/debounce";
import ModalWrapper from "../../generic/modal";
import { SearchConversationList } from "./SearchConversations.list";

export const ConversationHeader = ({
  searchInput,
  setIsNewConversation,
  setSearchInput,
  setConversationsFilter,
  convsersationsFilter,
}: {
  searchInput: string;
  setSearchInput: Function;
  setIsNewConversation: Function;
  setConversationsFilter: Function;
  convsersationsFilter: {
    unread: boolean;
    assigned: boolean;
    unresolved: boolean;
    resolved: boolean;
    unassigned: boolean;
    assigned_user: boolean;
  };
}) => {
  const searchIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    color: "#6b7280",
    cursor: "pointer",
  };

  const settingIconStyle: React.CSSProperties = {
    height: "33px",
    width: "33px",
    marginTop: "7px",
    cursor: "pointer",
  };

  const inputSearchStyle: React.CSSProperties = {
    borderRadius: "25px",
    border: "none",
    background: "#f5f6f7",
    padding: "10px",
  };

  const plusButtonStyle: React.CSSProperties = {
    background: "rgb(101, 59, 250)",
  };

  const plusIconStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    marginTop: "2px",
    color: "white",
    cursor: "pointer",
  };

  const boxStyle: React.CSSProperties = {
    borderBottom: "1px solid rgb(223, 226, 232)",
    minHeight: "80px",
    padding: "20px",
  };

  const dispatch: AppDispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [searchModal, setSearchModal] = useState(false);

  const handleOpenChange: DropdownProps["onOpenChange"] = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const handleSearchChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  }, 300);

  const filterItems = [
    {
      label: "Unread",
      value: "unread",
      key: "1",
    },
    {
      label:"My conversations",
      value: "assigned_user",
      key: "2"
    },
    {
      label: "Assigned",
      value: "assigned",
      key: "3",
    },
    {
      label: "Unassigned",
      value: "unassigned",
      key: "4",
    },
    {
      label: "Resolved",
      value: "resolved",
      key: "5",
    },
    {
      label: "Unresolved",
      value: "unresolved",
      key: "6",
    },
  ]

  const items: MenuProps["items"] = filterItems.map((item) => {
    return {
      label: (
        <Checkbox
          onChange={(e) => {
            setConversationsFilter({
              ...convsersationsFilter,
              [item.value]: e.target.checked,
            });
          }}
          value={convsersationsFilter[item.value as keyof typeof convsersationsFilter]}
          checked={convsersationsFilter[item.value as keyof typeof convsersationsFilter] ? true : false}
        >
          {item.label}
        </Checkbox>
      ),
      key: item.key,
    };
  })


  const handleNewConversation = () => {
    setIsNewConversation(true);
    dispatch(setSelectedConversation(null));
  };

  return (
    <Flex style={boxStyle} gap={10} vertical>
      <Flex justify={"space-between"} align={"flex-start"}>
        {searchModal && (
          <ModalWrapper title="Search Messages" setIsModalOpen={setSearchModal} isModalOpen={searchModal}>
            <SearchConversationList setIsmodal={setSearchModal} />
          </ModalWrapper>
        )}
        <Flex gap={6}>
          <Button type="primary" onClick={handleNewConversation} style={plusButtonStyle} size="large" shape="circle" icon={<FaPlus style={plusIconStyle} />} />
        </Flex>

        <Flex>
          <div onClick={() => setSearchModal(true)}>
            <Input
              className={"search-conversation-input"}
              style={{
                ...inputSearchStyle,
              }}
              onChange={handleSearchChange}
              size="middle"
              placeholder="Search"
              prefix={<IoSearch style={searchIconStyle} />}
            />
          </div>

          <Dropdown menu={{ items }} onOpenChange={handleOpenChange} open={open} trigger={["click"]}>
            <LuSettings2 style={settingIconStyle} strokeWidth={1} />
          </Dropdown>
        </Flex>
      </Flex>
      {Object.keys(convsersationsFilter).filter((key) => convsersationsFilter[key as keyof typeof convsersationsFilter]).length > 0 && (
        <Flex gap={6} wrap="wrap">
          {Object.keys(convsersationsFilter)
            .filter((key) => convsersationsFilter[key as keyof typeof convsersationsFilter])
            .map((key) => {
              return (
                <div>
                  <Tag style={{ cursor: "pointer" }} color="geekblue" key={key}>
                    {filterItems.find((item) => item.value === key )?.label}
                  </Tag>
                </div>
              );
            })}
          <Tag style={{ cursor: "pointer" }} onClick={() => setConversationsFilter({})}>
            clear
          </Tag>
        </Flex>
      )}
    </Flex>
  );
};
