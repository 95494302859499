import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface Tag {
    name: string
    category: string | null
    account_id: string
}

export interface TagState {
    data: Tag[]
}

const initialState: TagState = {
    data: []
}

export const tagsSlice = createSlice({
    name: "tags",
    initialState,
    reducers: {
        setTagsSlice: (state, action: PayloadAction<Tag[]>) => {

            action.payload.forEach((tag) => {
                const existingTag = state.data.find((t) => t.name === tag.name);
                if (!existingTag) {
                    state.data.push(tag);
                }
            })
        },
        addTagSlice: (state, action: PayloadAction<Tag>) => {
            console.log("payload", action.payload)
            state.data = [...state.data, action.payload];
        },

    },
});

export const { setTagsSlice, addTagSlice} = tagsSlice.actions;

export default tagsSlice.reducer;