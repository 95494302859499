import React, { useEffect } from "react";
import { usePushNotificationRegister } from "./config";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useGetContacts } from "../apis/contacts.api";

const usePushNotification = () => {
  usePushNotificationRegister();

  const { data: contacts } = useSelector((state: RootState) => state.contacts);

  const contactLastDateRef = React.useRef<any>(null);

  const { refetch } = useGetContacts({ updated_at: contactLastDateRef.current });

  useEffect(() => {
    if (contacts.length > 0) {
      const sortedContacts = [...contacts].sort((a: any, b: any) => b?.updated_at - a.updated_at);
      contactLastDateRef.current = sortedContacts[0].updated_at;
    }
  }, [contacts]);

  useEffect(() => {
    if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data && event.data.type === "SILENT_PUSH") {
          try {
            const data = JSON.parse(event.data.payload);
            if (data && data.data && data.data == "resync-contacts") {
              refetch();
            }
          } catch (error) {
            console.log("Error parsing payload:", error);
          }
        }
      });
    }

    return () => {
      if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.removeEventListener("message", () => {});
      }
    };
  }, []);
};

export default usePushNotification;
