import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TemplateUser } from "../../../hooks/apis/templates.api";

export interface UserTemplateState {
    data: TemplateUser[]
}

export const initialState: UserTemplateState = {
    data: [],
};

const userTemplateSlice = createSlice({
    name: "userTemplates",
    initialState,
    reducers: {
        setUserTemplatesSlice: (state, action: PayloadAction<TemplateUser[]>) => {
            action.payload.forEach((template) => {
                if (state.data.some((item) => item.template_id === template.template_id && item.user_id === template.user_id)) return
                state.data.push(template)
            })
        },
     
        addUserTemplateSlice: (state, action: PayloadAction<TemplateUser>) => {
            state.data.push(action.payload);
        },

    }
});


export const { setUserTemplatesSlice, addUserTemplateSlice } = userTemplateSlice.actions;

export default userTemplateSlice.reducer;